import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import styles from "./GranosBox.module.scss";
import boxStyles from "../box.module.scss";
import Api from "../../../api";
import { Spinner } from "react-bootstrap";

const GranosBox = ({ title }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [taxKeys, setTaxKeys] = useState([]);

  const extractTaxKeys = useCallback(data => {
    setTaxKeys(current => {
      const newKeys = [...current];
      data.forEach(d => {
        if (d.impuestos) {
          Object.keys(d.impuestos).forEach(i => {
            if (!newKeys.includes(i)) {
              newKeys.push(i);
            }
          });
        }
      });
      return newKeys;
    })
  }, []);

  useEffect(() => {
    // setData(SAMPLE);
    setLoading(true);
    Api.Investments.getState('GRANOS').then(res => {
      setData(res);
      extractTaxKeys(res);
    }).then(() => setLoading(false));
  }, [extractTaxKeys]);

console.log('granos data', data)

  const single = data.length === 1;

  return (
    <div className={styles.root + " custom-card"}>
      <h4>Resumen {title}</h4>

      {loading && <Spinner animation="border" role="status" variant="secondary" style={{ marginLeft: '1.5rem', marginTop: '0.5rem' }} />}
      {data.length > 0 &&
        <div className={`${styles.container} ${!single ? styles.frozen : ''}`}>
          <table className={boxStyles.box}>
            <tbody>
              <tr className={boxStyles.title}>
                <th className={!single ? boxStyles.frozen : ''}>Liquidación</th>
                {data.map((d, i) => <th className={`${d.current ? boxStyles.current : ''} ${i === 0 && !single ? boxStyles.frozen2 : ''}`}>{d.liquidacion}</th>)}
              </tr>
              <tr>
                <td className={!single ? boxStyles.frozen : ''}>Saldo Inicio</td>
                {data.map((d, i) => <NumberCell value={d.saldoInicio} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
              <tr>
                <td className={!single ? boxStyles.frozen : ''}>Aportes</td>
                {data.map((d, i) => <NumberCell value={d.aportes} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
              <tr>
                <td className={!single ? boxStyles.frozen : ''}>Reasignaciones</td>
                {data.map((d, i) => <NumberCell value={d.reasignaciones} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
              <tr>
                <td className={`${!single ? boxStyles.frozen : ''} ${boxStyles.highlight} ${boxStyles.colored}`}>Capital que participa en la campaña</td>
                {data.map((d, i) => <NumberCell value={d.capital} current={d.current} frozen={i === 0 && !single} className={`${boxStyles.highlight}`} />)}
              </tr>
              <tr>
                <td className={!single ? boxStyles.frozen : ''}>Renta</td>
                {data.map((d, i) => <NumberCell value={d.renta} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
              <tr>
                <td className={!single ? boxStyles.frozen : ''}>Resultado de la Inversión ACDA</td>
                {data.map((d, i) => <NumberCell value={d.resultado} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
              <tr>
                <th className={!single ? boxStyles.frozen : ''}>Inversión sin efecto impuestos</th>
                {data.map((d, i) => <NumberHeader value={d.inversionSinImpuestos} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
              {taxKeys.map((k, x) => (
                <tr key={`tax${x}`}>
                  <td className={!single ? boxStyles.frozen : ''}>{k}</td>
                  {data.map((d, i) => <NumberCell value={(d.impuestos || {})[k] || 0} current={d.current} frozen={i === 0 && !single} />)}
                </tr>
              ))}
              {/* <tr>
                <td className={!single ? boxStyles.frozen : ''}>Bienes personales</td>
                {data.map((d, i) => <NumberCell value={d.bienesPersonales} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
              <tr>
                <td className={!single ? boxStyles.frozen : ''}>Retención de ganancias</td>
                {data.map((d, i) => <NumberCell value={d.retencionGanancias} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
              <tr>
                <td className={!single ? boxStyles.frozen : ''}>Retención de ganancias (Auto)</td>
                {data.map((d, i) => <NumberCell value={d.retencionGananciasAuto} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
              <tr>
                <td className={!single ? boxStyles.frozen : ''}>Ganancia mínima presunta</td>
                {data.map((d, i) => <NumberCell value={d.gananciaMinimaPresunta} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
              <tr>
                <td className={!single ? boxStyles.frozen : ''}>Imp. al débito y crédito bancario 34%</td>
                {data.map((d, i) => <NumberCell value={d.impDebCred} current={d.current} frozen={i === 0 && !single} />)}
              </tr> */}
              <tr>
                <th className={!single ? boxStyles.frozen : ''}>Efecto de impuestos campaña</th>
                {data.map((d, i) => <NumberHeader value={d.efectoImpCampana} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
              <tr>
                <th className={!single ? boxStyles.frozen : ''}>Efecto de impuestos acumulado</th>
                {data.map((d, i) => <NumberHeader value={d.efectoImpAcumulado} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
              <tr>
                <td className={!single ? boxStyles.frozen : ''}>Retiros</td>
                {data.map((d, i) => <NumberCell value={d.retiros} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
              <tr>
                <td className={!single ? boxStyles.frozen : ''}>Otros</td>
                {data.map((d, i) => <NumberCell value={d.otros} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
              <tr>
                <th className={!single ? boxStyles.frozen : ''}>Saldo en cuenta particular</th>
                {data.map((d, i) => <NumberHeader value={d.saldoCuenta} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
              <tr>
                <td className={`${!single ? boxStyles.frozen : ''} ${boxStyles.highlight}`}>Capital fideicomitido</td>
                {data.map((d, i) => <NumberCell value={d.capitalFideicomitido} current={d.current} frozen={i === 0 && !single} className={boxStyles.highlight} />)}
              </tr>
              <tr>
                <th className={!single ? boxStyles.frozen : ''}>Participación</th>
                {data.map((d, i) => <PercentHeader value={d.participacion} current={d.current} frozen={i === 0 && !single} />)}
              </tr>
            </tbody>
          </table>
        </div>
      }
    </div>
  );
};

const nf = new Intl.NumberFormat(undefined, { maximumFractionDigits: 2 });
const pf = new Intl.NumberFormat(undefined, { maximumFractionDigits: 6, style: "percent" });

const NumberHeader = ({ value, current, frozen, className }) => <th className={`${current ? boxStyles.current : ''} ${frozen ? boxStyles.frozen2 : ''} ${className || ''}`}>{nf.format(value)}</th>;
const NumberCell = ({ value, current, frozen, className }) => <td className={`${current ? boxStyles.current : ''} ${frozen ? boxStyles.frozen2 : ''} ${className || ''}`}>{nf.format(value)}</td>;
const PercentHeader = ({ value, current, frozen, className }) => <th className={`${current ? boxStyles.current : ''} ${frozen ? boxStyles.frozen2 : ''} ${className || ''}`}>{pf.format(value)}</th>;

// const SAMPLE = [
  // {
  //   current: true,
  //   liquidacion: '20-21',
  //   saldoInicio: 0,
  //   aportes: 10000,
  //   reasignaciones: 0,
  //   capital: 10000,
  //   renta: 500,
  //   resultado: 500,
  //   inversionSinImpuestos: 10500,
  //   bienesPersonales: 0,
  //   retencionGanancias: -111,
  //   retencionGananciasAuto: -8,
  //   gananciaMinimaPresunta: 0,
  //   impDebCred: -91,
  //   efectoImpCampana: -210,
  //   efectoImpAcumulado: -210,
  //   retiros: -290,
  //   otros: 0,
  //   saldoCuenta: 10000,
  //   capitalFideicomitido: 13109.886,
  //   participacion: 0.00076
  // },
//   {
//     liquidacion: '19-20',
//     saldoInicio: 0,
//     aportes: 10000,
//     reasignaciones: 0,
//     capital: 10000,
//     renta: 500,
//     resultado: 500,
//     inversionSinImpuestos: 10500,
//     bienesPersonales: 0,
//     retencionGanancias: -111,
//     retencionGananciasAuto: -8,
//     gananciaMinimaPresunta: 0,
//     impDebCred: -91,
//     efectoImpCampana: -210,
//     efectoImpAcumulado: -210,
//     retiros: -290,
//     otros: 0,
//     saldoCuenta: 10000,
//     capitalFideicomitido: 13109.886,
//     participacion: 0.00076
//   },
//   {
//     liquidacion: '18-19',
//     saldoInicio: 0,
//     aportes: 10000,
//     reasignaciones: 0,
//     capital: 10000,
//     renta: 500,
//     resultado: 500,
//     inversionSinImpuestos: 10500,
//     bienesPersonales: 0,
//     retencionGanancias: -111,
//     retencionGananciasAuto: -8,
//     gananciaMinimaPresunta: 0,
//     impDebCred: -91,
//     efectoImpCampana: -210,
//     efectoImpAcumulado: -210,
//     retiros: -290,
//     otros: 0,
//     saldoCuenta: 10000,
//     capitalFideicomitido: 13109.886,
//     participacion: 0.00076
//   },
//   {
//     liquidacion: '18-19',
//     saldoInicio: 0,
//     aportes: 10000,
//     reasignaciones: 0,
//     capital: 10000,
//     renta: 500,
//     resultado: 500,
//     inversionSinImpuestos: 10500,
//     bienesPersonales: 0,
//     retencionGanancias: -111,
//     retencionGananciasAuto: -8,
//     gananciaMinimaPresunta: 0,
//     impDebCred: -91,
//     efectoImpCampana: -210,
//     efectoImpAcumulado: -210,
//     retiros: -290,
//     otros: 0,
//     saldoCuenta: 10000,
//     capitalFideicomitido: 13109.886,
//     participacion: 0.00076
//   },
//   {
//     liquidacion: '18-19',
//     saldoInicio: 0,
//     aportes: 10000,
//     reasignaciones: 0,
//     capital: 10000,
//     renta: 500,
//     resultado: 500,
//     inversionSinImpuestos: 10500,
//     bienesPersonales: 0,
//     retencionGanancias: -111,
//     retencionGananciasAuto: -8,
//     gananciaMinimaPresunta: 0,
//     impDebCred: -91,
//     efectoImpCampana: -210,
//     efectoImpAcumulado: -210,
//     retiros: -290,
//     otros: 0,
//     saldoCuenta: 10000,
//     capitalFideicomitido: 13109.886,
//     participacion: 0.00076
//   },
//   {
//     liquidacion: '18-19',
//     saldoInicio: 0,
//     aportes: 10000,
//     reasignaciones: 0,
//     capital: 10000,
//     renta: 500,
//     resultado: 500,
//     inversionSinImpuestos: 10500,
//     bienesPersonales: 0,
//     retencionGanancias: -111,
//     retencionGananciasAuto: -8,
//     gananciaMinimaPresunta: 0,
//     impDebCred: -91,
//     efectoImpCampana: -210,
//     efectoImpAcumulado: -210,
//     retiros: -290,
//     otros: 0,
//     saldoCuenta: 10000,
//     capitalFideicomitido: 13109.886,
//     participacion: 0.00076
//   }
// ];

export default GranosBox;