import React, { useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DataForm from "./DataForm";
import { Field } from "formik";
import { PERSONA_JURIDICA } from "./entityTypes";
import Select from "../../components/select/Select";
import { useIndustries, useProfessions, useSectors } from "../../api/support";

export const workDataInitialValues = {
  profession: '',
  industry: '',
  company: '',
  sectorPosition: ''
};

const WorkData = ({ values, ...stepProps }) => {
  const initialValues = useMemo(() => ({
    ...workDataInitialValues,
    ...values
  }), [values]);

  return (
    <DataForm {...stepProps} initialValues={initialValues}>
      <WorkDataFormInputs entityType={values.entityType} />
    </DataForm>
  );
};

export const WorkDataFormInputs = ({ entityType, isSubmitting }) => {
  const industries = useIndustries();
  const professions = useProfessions();
  const sectors = useSectors();

  const pj = entityType === PERSONA_JURIDICA;

  return (
    <>
      {!pj &&
      <Form.Group className="mb-3" controlId="profession">
        <Form.Label>Profesión</Form.Label>
        <Select name="profession" disabled={isSubmitting}>
          <option>Profesión</option>
          {professions.map((i, k) => (
            <option value={i} key={k}>{i}</option>
          ))}
        </Select>
      </Form.Group>
      }
      <Form.Group className="mb-3" controlId="industry">
        <Form.Label>Industria</Form.Label>
        <Select name="industry" disabled={isSubmitting}>
          <option>Industria</option>
          {industries.map((i, k) => (
            <option value={i} key={k}>{i}</option>
          ))}
        </Select>
      </Form.Group>
      {pj &&
      <>
        <Form.Group className="mb-3" controlId="marketShare">
          <Form.Label>Tamaño</Form.Label>
          <Select name="marketShare" disabled={isSubmitting}>
            <option>Tamaño</option>
            <option value="PEQUEÑA EMPRESA">Pequeña Empresa</option>
            <option value="MEDIANA EMPRESA">Mediana Empresa</option>
            <option value="GRAN EMPRESA">Gran Empresa</option>
          </Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="webSite">
          <Form.Label>Sitio Web</Form.Label>
          <Form.Control type="text" name="webSite" placeholder="Sitio Web" disabled={isSubmitting} as={Field} />
        </Form.Group>
      </>
      }
      {!pj &&
      <Row className="mb-3">
        <Form.Group as={Col} controlId="company">
          <Form.Label>Compañía*</Form.Label>
          <Form.Control type="text" name="company" placeholder="Compañía" required disabled={isSubmitting} as={Field} />
        </Form.Group>
        <Form.Group as={Col} controlId="sectorPosition">
          <Form.Label>Sector | Cargo</Form.Label>
          <Select name="sectorPosition" disabled={isSubmitting}>
            <option>Sector | Cargo</option>
            {sectors.map((i, k) => (
              <option value={i} key={k}>{i}</option>
            ))}
          </Select>
        </Form.Group>
      </Row>
      }
    </>
  );
};

export default WorkData;