import React, { useEffect, useState } from "react";
import styles from "./ProjectVideo.module.scss";
import { useParams } from "react-router-dom";
import Api from "../../api";

const ProjectVideo = () => {
  const { projectId } = useParams();

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    Api.Docs.getProjectDocs(projectId).then(content => {
      console.log('content', content)
      setVideos(content.filter(c => c.contentType === 'VIDEO'));
    }).catch(err => console.error(err));
  }, [projectId]);

  console.log('set videos', videos)

  return (
    <div className={styles.root}>
      {videos.map((v, i) => (
        <div className="mb-4">
          <iframe key={i} width="720" height="405" title="youtube video"
            src={`https://www.youtube.com/embed/${v.videoURL.substr(v.videoURL.lastIndexOf('/') + 1)}`}></iframe>
        </div>
      ))}
    </div>
  );
};

export default ProjectVideo;