import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import ErrorPage from './error/ErrorPage';
import Login from './modules/login/Login';
import SignUp from './modules/signup/SignUp';
import { getUser } from './session';
import Home from './modules/home/Home';
import Projects from './modules/projects/Projects';
import News from './modules/news/News';
import Investments from './modules/investments/Investments';
import InvestmentDocument from './modules/investments/InvestmentDocument';
import Project from './modules/projects/Project';
import ProjectDocument from './modules/projects/ProjectDocument';
import ProjectVideo from './modules/projects/ProjectVideo';
import ProjectNews from './modules/projects/ProjectNews';
import Profile from './modules/profile/Profile';
import PasswordChange from './modules/pwdchange/PasswordChange';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    loader: () => {
      if (!getUser()) {
        return redirect('/login');
      }
      return null;
    },
    children: [
      {
        path: "",
        element: <Home />
      },
      {
        path: "projects/:projectId",
        element: <Project />,
        children: [
          {
            path: "docs/:docId",
            element: <ProjectDocument />
          },
          {
            path: "docs",
            element: <ProjectDocument />
          },
          {
            path: "videos",
            element: <ProjectVideo />
          },
          {
            path: "news",
            element: <ProjectNews />
          }
        ]
      },
      {
        path: "projects",
        element: <Projects />
      },
      {
        path: "investments/:investmentId/:docId",
        element: <InvestmentDocument />
      },
      {
        path: "investments/:investmentId",
        element: <Investments />
      },
      {
        path: "investments",
        element: <Investments />
      },
      {
        path: "news",
        element: <News />
      },
      {
        path: "profile",
        element: <Profile />
      },
      {
        path: "pwdchange",
        element: <PasswordChange />
      }
    ]
  },
  {
    path: "login",
    element: <Login />
  },
  {
    path: "recover",
    element: <Login />
  },
  {
    path: "resetconfirm",
    element: <Login />
  },
  {
    path: "signup",
    element: <SignUp />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
