import call, { callAuthEvent } from "./call";

const BASE_URL = `https://api.sitrack.io/edna`;
// const BASE_URL = `https://beta-api.sitrack.io/edna`;
const EVENT_URL = `kNoyY1n2gh`;

const Session = {
    login: (user, password) => {
        return fetch(`${BASE_URL}/Session`, {
                method: 'POST',
                body: '{}',
                headers: {
                    'Authorization': `basic ${window.btoa(`${user}:${password}`)}`
                }
            }).then(res => {
                if (!res.ok) {
                    // return res.json().then(json => {
                    //     throw new Error(extractError(json));
                    //  });
                    throw new Error('Usuario o contraseña incorrectos');
                } else {
                    return res.json();
                }
            })
    },
    resetPassword: (cuit, email) => call(EVENT_URL, 'POST', {
        message: 'events.adblick.ib_restore.password.front',
        userName: cuit,
        email: email
    }),
    changePassword: (password) => callAuthEvent('event/flow/message?wait=true', 'POST', {
        password,
        message: 'events.adblick.ib_change.password.front'
    }).then(r => {
        if (r.result && r.result.length > 0) {
            const resObject = r.result[0];
            if (resObject.errors) {
                throw new Error('Error al cambiar la contraseña: ' + resObject.errors);
            }
        }
        return r;
    })
};

export default Session;