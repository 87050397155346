import React, { useCallback } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import "./DataForm.scss";

const DataForm = ({ initialValues, step, totalSteps, subtitle, children, onUpdateValues, onNext, onPrevious, onFinish, hideSideNote, submitting }) => {
  const onSubmit = useCallback((values, { setSubmitting }) => {
    onUpdateValues(values);
    setSubmitting(false);

    if (step !== totalSteps) {
      onNext();
    } else {
      onFinish(values);
    }
  }, [onUpdateValues, onNext, onFinish, step, totalSteps]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <FormikForm style={{ flex: 1 }}>
          <div className="data-form">
            <div className="step-number">Paso {step}/{totalSteps}</div>
            <h3>{subtitle}</h3>

            <div className="form-wrapper">
              <div className="form-content">
                {children}
              </div>
              {/* {!hideSideNote &&
                <div className="form-side">
                  * Campos de requerimiento obligatorio.
                </div>
              } */}
            </div>
            <div className="navigation">
              <div className="required-legend">
              {!hideSideNote && "* Campos de requerimiento obligatorio."}
              </div>
              <Button variant="link" disabled={submitting || step === 1} onClick={onPrevious}>Volver</Button>
              {!submitting &&
              <Button type="submit" variant="primary" className="ms-5" style={{ width: 150 }}>
                {step !== totalSteps ? "Siguiente Paso" : "Finalizar"}
              </Button>
              }
              {submitting &&
              <Button type="button" variant="primary" className="ms-5" disabled style={{ width: 150 }}>
                <Spinner animation="border" variant="light" size="sm" />
              </Button>
              }
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default DataForm;