import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DocCard from "../../components/cards/DocCard";
import { SAMPLE_PROJECTS } from "../../sample/sampleProjects";
import styles from "./ProjectDocument.module.scss";
import Api from "../../api";
import DocumentRenderer from "../../components/documents/DocumentRenderer";

const ProjectDocument = () => {
  const [doc, setDoc] = useState();
  const [docs, setDocs] = useState([]);

  const { projectId, docId } = useParams();

  useEffect(() => {
    Api.Docs.getProjectDocs(projectId).then(content => {
      setDocs(content.filter(c => c.contentType === 'DOCUMENTO'));
    }).catch(err => console.error(err));
  }, [projectId]);
  
  useEffect(() => {
    if (projectId) {
      const project = SAMPLE_PROJECTS.find(p => p.id === projectId);
      const docs = [
        {
          id: "1",
          title: project.title,
          subtitle: "Carátula de proyecto"
        }
      ];
      setDocs(docs);
    }
  }, [projectId]);
  
  useEffect(() => {
    setDoc(docId ? docs.find(d => d.id === docId) : undefined);
  }, [docs, docId]);

  return (
    <div className={styles.root}>
      <Row>
        <Col xs={12} xl={8}>
        {doc && <DocumentRenderer doc={doc} />
        }
        </Col>
        <Col className="d-flex flex-column mt-3 mt-xl-0" xs={12} xl={4}>
          <h4 style={{ fontSize: "14pt", borderBottom: "1px solid #7070704d" }} className="mb-4 pb-3">Archivos PDF</h4>
          {docs.map((d, i) => <DocCard key={i} doc={d} linkTo={`/projects/${projectId}/docs/${d.id}`} />)}
        </Col>
      </Row>
    </div>
  );
};

export default ProjectDocument;