import React, { useCallback, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DataForm from "./DataForm";
import { Field } from "formik";
import FormikDateTime, { DateTimePicker } from "../../components/datepicker/FormikDateTime";
import { PERSONA_JURIDICA } from "./entityTypes";
import Select from "../../components/select/Select";
import { useCountries, useNationalities, useProvinces } from "../../api/support";

export const personalDataInitialValues = {
  investorName: '',
  investorLastName: '',
  investorDni: '',
  investorCUIT: '',
  birthday: '',
  gender: '',
  maritalStatus: '',
  nationality: '',
  address: '',
  postalCode: '',
  city: '',
  state: '',
  country: '',
  emailMain: '',
  emailSecondary: '',
  phoneNumber: '',
  mobileNumber: '',
  spouseIdNumber: 0,
  spouseName: '',
  spouseLastName: '',
  substituteBeneficiaryIdNumber: 0,
  substituteBeneficiaryName: '',
  substituteBeneficiaryLastName: '',
  substituteBeneficiaryEmail: '',
  substituteBeneficiaryPhone: ''
};

const PersonalData = ({ values = {}, ...stepProps }) => {
  const initialValues = useMemo(() => ({
    ...personalDataInitialValues,
    ...values
  }), [values]);
  return (
    <DataForm {...stepProps} initialValues={initialValues}>
      <PersonalDataFormInputs entityType={values.entityType} />
    </DataForm>
  );
};

const MaritalStatusOptions = () => (
  <>
    <option>Estado civil</option>
    <option value="SOLTERO/A">Soltero/a</option>
    <option value="CASADO/A">Casado/a</option>
    <option value="DIVORCIADO/A">Divorciado/a</option>
    <option value="VIUDO/A">Viudo/a</option>
    <option value="CONVIVIENTE">Conviviente</option>
  </>
);

export const PersonalDataFormInputs = ({ editing, entityType, isSubmitting }) => {
  const countries = useCountries();
  const provinces = useProvinces();
  const nationalities = useNationalities();
  
  const pj = entityType === PERSONA_JURIDICA;

  return (
    <>
      {!pj &&
      <>
        <Form.Group className="mb-3" controlId="investorName">
          <Form.Label>Nombre*</Form.Label>
          <Form.Control type="text" name="investorName" placeholder="Nombre" required disabled={isSubmitting} as={Field} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="investorLastName">
          <Form.Label>Apellido*</Form.Label>
          <Form.Control type="text" name="investorLastName" placeholder="Apellido" required disabled={isSubmitting} as={Field} />
        </Form.Group>
      </>
      }
      {pj &&
      <>
        <Form.Group className="mb-3" controlId="businessName">
          <Form.Label>Razón social*</Form.Label>
          <Form.Control type="text" name="businessName" placeholder="Razón social" required disabled={isSubmitting} as={Field} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="societyType">
          <Form.Label>Tipo de sociedad</Form.Label>
          <Select name="societyType" disabled={isSubmitting}>
              <option>Tipo de sociedad</option>
              <option value="S.A.">S.A.</option>
              <option value="S.R.L.">S.R.L.</option>
              <option value="S.H.">S.H.</option>
              <option value="S.A.S.">S.A.S.</option>
              <option value="Fundación">Fundación</option>
            </Select>
        </Form.Group>
      </>
      }
      <Row className="mb-3">
        {!pj &&
        <Form.Group as={Col} controlId="investorDni">
          <Form.Label>DNI*</Form.Label>
          <Form.Control type="number" name="investorDni" placeholder="DNI" required disabled={editing || isSubmitting} as={Field} />
        </Form.Group>
        }
        <Form.Group as={Col} controlId="investorCUIT">
          <Form.Label>CUIT*</Form.Label>
          <Form.Control type="text" name="investorCUIT" placeholder="CUIT" required disabled={editing || isSubmitting} as={Field} />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="birthday">
          <Form.Label>{!pj ? 'Fecha de Nacimiento*' : 'Fecha de inicio de actividades*'}</Form.Label>
          <Field name="birthday" placeholder="dd/mm/aaaa" required dateFormat="DD/MM/YYYY" timeFormat={false} valueFormat="DD-MM-YYYY"
                 component={FormikDateTime} disabled={isSubmitting} />
        </Form.Group>
        {!pj &&
        <Form.Group as={Col} controlId="gender">
          <Form.Label>Género*</Form.Label>
          <Select name="gender" required disabled={isSubmitting}>
            <option>Género</option>
            <option value="FEMENINO">Femenino</option>
            <option value="MASCULINO">Masculino</option>
          </Select>
        </Form.Group>
        }
      </Row>
      {!pj &&
      <Row className="mb-3">
        <Form.Group as={Col} controlId="maritalStatus">
          <Form.Label>Estado civil*</Form.Label>
          <Select name="maritalStatus" required disabled={isSubmitting}>
            <MaritalStatusOptions />
          </Select>
        </Form.Group>
        <Form.Group as={Col} controlId="nationality">
          <Form.Label>Nacionalidad</Form.Label>
          {/* <Form.Control type="text" name="nationality" placeholder="Nacionalidad" disabled={isSubmitting} as={Field} /> */}
          <Select name="state" disabled={isSubmitting}>
            <option>Nacionalidad</option>
            {nationalities.map((i, k) => (
              <option value={i} key={k}>{i}</option>
            ))}
          </Select>
        </Form.Group>
      </Row>
      }
      <Row className="mb-3">
        <Form.Group as={Col} xs={9} controlId="address">
          <Form.Label>Domicilio*</Form.Label>
          <Form.Control type="text" name="address" placeholder="Domicilio" required disabled={isSubmitting} as={Field} />
        </Form.Group>
        <Form.Group as={Col} xs={3} controlId="postalCode">
          <Form.Label>CP*</Form.Label>
          <Form.Control type="number" name="postalCode" placeholder="CP" required disabled={isSubmitting} as={Field} />
        </Form.Group>
      </Row>
      <Form.Group className="mb-3" controlId="city">
        <Form.Label>Localidad*</Form.Label>
        <Form.Control type="text" name="city" placeholder="Localidad" required disabled={isSubmitting} as={Field} />
      </Form.Group>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="state">
          <Form.Label>Provincia*</Form.Label>
          {/* <Form.Control type="text" name="state" placeholder="Provincia" required disabled={isSubmitting} as={Field} /> */}
          <Select name="state" required disabled={isSubmitting}>
            <option>Provincia</option>
            {provinces.map((i, k) => (
              <option value={i} key={k}>{i}</option>
            ))}
          </Select>
        </Form.Group>
        <Form.Group as={Col} controlId="country">
          <Form.Label>País*</Form.Label>
          {/* <Form.Control type="text" name="country" placeholder="País" required disabled={isSubmitting} as={Field} /> */}
          <Select name="country" required disabled={isSubmitting}>
            <option>País</option>
            {countries.map((i, k) => (
              <option value={i} key={k}>{i}</option>
            ))}
          </Select>
        </Form.Group>
      </Row>
      <Form.Group className="mb-3" controlId="emailMain">
        <Form.Label>Email principal*</Form.Label>
        <Form.Control type="email" name="emailMain" placeholder="Dirección de Email" required disabled={isSubmitting} as={Field} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="emailSecondary">
        <Form.Label>Email secundario</Form.Label>
        <Form.Control type="email" name="emailSecondary" placeholder="Dirección de Email" disabled={isSubmitting} as={Field} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="phoneNumber">
        <Form.Label>Teléfono fijo*</Form.Label>
        <Form.Control type="phone" name="phoneNumber" placeholder="Teléfono fijo" required disabled={isSubmitting} as={Field} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="mobileNumber">
        <Form.Label>Teléfono móvil*</Form.Label>
        <Form.Control type="phone" name="mobileNumber" placeholder="Teléfono móvil" required disabled={isSubmitting} as={Field} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="contactName">
        <Form.Label>Nombre de contacto{pj ? '*' : ''}</Form.Label>
        <Form.Control type="text" name="contactName" placeholder="Nombre de contacto" required={pj} disabled={isSubmitting} as={Field} />
      </Form.Group>
      {!pj &&
      <>
        <h6 className="mt-5 border-1 border-bottom">Cónyuge</h6>
        <Form.Group className="mb-3" controlId="spouseIdNumber">
          <Form.Label>DNI</Form.Label>
          <Form.Control type="number" name="spouseIdNumber" placeholder="DNI de cónyuge" disabled={isSubmitting} as={Field} />
        </Form.Group>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="spouseName">
            <Form.Label>Nombre</Form.Label>
            <Form.Control type="text" name="spouseName" placeholder="Nombre de cónyuge" disabled={isSubmitting} as={Field} />
          </Form.Group>
          <Form.Group as={Col} controlId="spouseLastName">
            <Form.Label>Apellido</Form.Label>
            <Form.Control type="text" name="spouseLastName" placeholder="Apellido de cónyuge" disabled={isSubmitting} as={Field} />
          </Form.Group>
        </Row>
        <h6 className="mt-5 border-1 border-bottom">Beneficiario sustituto</h6>
        <Form.Group className="mb-3" controlId="substituteBeneficiaryIdNumber">
          <Form.Label>DNI</Form.Label>
          <Form.Control type="number" name="substituteBeneficiaryIdNumber" placeholder="DNI de beneficiario" disabled={isSubmitting} as={Field} />
        </Form.Group>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="substituteBeneficiaryName">
            <Form.Label>Nombre</Form.Label>
            <Form.Control type="text" name="substituteBeneficiaryName" placeholder="Nombre de beneficiario" disabled={isSubmitting} as={Field} />
          </Form.Group>
          <Form.Group as={Col} controlId="substituteBeneficiaryLastName">
            <Form.Label>Apellido</Form.Label>
            <Form.Control type="text" name="substituteBeneficiaryLastName" placeholder="Apellido de beneficiario" disabled={isSubmitting} as={Field} />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="substituteBeneficiaryEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" name="substituteBeneficiaryEmail" placeholder="Email de beneficiario" disabled={isSubmitting} as={Field} />
          </Form.Group>
          <Form.Group as={Col} controlId="substituteBeneficiaryPhone">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control type="phone" name="substituteBeneficiaryPhone" placeholder="Teléfono de beneficiario" disabled={isSubmitting} as={Field} />
          </Form.Group>
        </Row>
      </>
      }
      {pj &&
      <>
        <Form.Group className="mb-3" controlId="contactLastName">
          <Form.Label>Apellido de contacto*</Form.Label>
          <Form.Control type="text" name="contactLastName" placeholder="Apellido de contacto" required disabled={isSubmitting} as={Field} />
        </Form.Group>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="contactRole">
            <Form.Label>Rol de contacto*</Form.Label>
            <Form.Control type="text" name="contactRole" placeholder="Rol de contacto" required disabled={isSubmitting} as={Field} />
          </Form.Group>
          <Form.Group as={Col} controlId="contactGender">
            <Form.Label>Género de contacto</Form.Label>
            <Select name="contactGender" disabled={isSubmitting}>
              <option>Género</option>
              <option value="FEMENINO">Femenino</option>
              <option value="MASCULINO">Masculino</option>
            </Select>
          </Form.Group>
        </Row>
        <Field name="legalRepresentative">{LegalRepresentativeFormInputs}</Field>
        <Field name="controllingEntity">{ControllingEntityFormInputs}</Field>
      </>
      }
    </>
  );
};

const LegalRepresentativeFormInputs = ({ field, form }) => {
  const { name, value = {} } = field;
  const { isSubmitting } = form;
  const onFieldChange = useCallback((newValue) => {
    form.setFieldValue(name, newValue);
  }, [form, name]);

  const commonProps = {
    parent: value,
    onChange: onFieldChange,
    disabled: isSubmitting
  };

  const nationalities = useNationalities();

  return (
    <>
      <h6 className="mt-5 border-1 border-bottom">Representante Legal</h6>
      <Row className="mb-3">
        <NestedField label="Nombre" fieldName="name" {...commonProps} as={Col} />
        <NestedField label="Apellido" fieldName="lastName" {...commonProps} as={Col} />
      </Row>
      <Row className="mb-3">
        <NestedDatePicker label="Fecha de Nacimiento" fieldName="birthday" {...commonProps} as={Col} />
        <NestedSelect label="Género" fieldName="gender" {...commonProps} as={Col}>
          <option>Género</option>
          <option value="FEMENINO">Femenino</option>
          <option value="MASCULINO">Masculino</option>
        </NestedSelect>
        {/* <NestedField label="Género" fieldName="gender" parent={value} onChange={onFieldChange} as={Col} /> */}
      </Row>
      <Row className="mb-3">
        <NestedSelect label="Estado civil" fieldName="maritalStatus" {...commonProps} as={Col}>
          <MaritalStatusOptions />
        </NestedSelect>
        <NestedSelect label="Nacionalidad" fieldName="nationality" {...commonProps} as={Col}>
          <option>Nacionalidad</option>
          {nationalities.map((i, k) => (
            <option value={i} key={k}>{i}</option>
          ))}
        </NestedSelect>
      </Row>
      <Row className="mb-3">
        <NestedField label="Rol" fieldName="role" {...commonProps} as={Col} />
        <NestedDatePicker label="Fecha hasta" fieldName="roleEndDate" {...commonProps} as={Col} />
      </Row>
      <Row className="mb-3">
        <NestedField label="Email" fieldName="email" {...commonProps} as={Col} />
        <NestedField label="Teléfono" fieldName="phone" type="phone" {...commonProps} as={Col} />
      </Row>
    </>
  );
};

const ControllingEntityFormInputs = ({ field, form }) => {
  const { name, value = {} } = field;
  const { isSubmitting } = form;
  const onFieldChange = useCallback((newValue) => {
    form.setFieldValue(name, newValue);
  }, [form, name]);

  const commonProps = {
    parent: value,
    onChange: onFieldChange,
    disabled: isSubmitting
  };

  const countries = useCountries();
  const provinces = useProvinces();

  return (
    <>
      <h6 className="mt-5 border-1 border-bottom">Entidad controlante</h6>
      <NestedField className="mb-3" label="Número de identificación" fieldName="idNumber" {...commonProps} />
      <NestedField className="mb-3" label="Razón social" fieldName="businessName" {...commonProps} />
      <Row className="mb-3">
        <NestedField label="Domicilio" fieldName="address" {...commonProps} as={Col} />
        <NestedField label="CP" fieldName="postalCode" {...commonProps} as={Col} />
      </Row>
      <NestedField className="mb-3" label="Localidad" fieldName="city" {...commonProps} />
      <Row className="mb-3">
        <NestedSelect label="País" fieldName="country" {...commonProps} as={Col}>
          <option>País</option>
          {countries.map((i, k) => (
            <option value={i} key={k}>{i}</option>
          ))}
        </NestedSelect>
        <NestedSelect label="Provincia" fieldName="state" {...commonProps} as={Col}>
          <option>Provincia</option>
          {provinces.map((i, k) => (
            <option value={i} key={k}>{i}</option>
          ))}
        </NestedSelect>
      </Row>
      <NestedField label="Participacion (%)" fieldName="participationPercentage" type="number" {...commonProps} as={Col} />
    </>
  );
};

// const useFieldChange = (parent, fieldName, onChange) => {
//   const onFieldChange = useCallback(e => {
//     onChange({ ...parent, [fieldName]: e.target.value });
//   }, [onChange, parent, fieldName]);

//   return onFieldChange;
// };

const NestedField = ({ label, type = "text", parent, fieldName, onChange, required, disabled, ...rootProps }) => {
  const onFieldChange = useCallback(e => {
    const value = e.target.value;
    let parsed;
    const val = /number|range/.test(type)
      ? ((parsed = parseFloat(value)), isNaN(parsed) ? '' : parsed)
      : value;
    onChange({ ...parent, [fieldName]: val });
  }, [onChange, parent, fieldName, type]);
  
  return (
    <Form.Group {...rootProps}>
      <Form.Label>{label}</Form.Label>
      <Form.Control type={type} value={parent[fieldName]} onChange={onFieldChange} required={required} disabled={disabled} />
    </Form.Group>
  );
};

const NestedDatePicker = ({ label, parent, fieldName, onChange, required, disabled, ...rootProps }) => {
  const onFieldChange = useCallback(newValue => {
    onChange({ ...parent, [fieldName]: newValue });
  }, [onChange, parent, fieldName]);

  return (
    <Form.Group {...rootProps}>
      <Form.Label>{label}</Form.Label>
      <DateTimePicker placeholder="dd/mm/aaaa" required={required} dateFormat="DD/MM/YYYY" disabled={disabled}
        timeFormat={false} valueFormat="DD-MM-YYYY" value={parent[fieldName]} onChange={onFieldChange} />
    </Form.Group>
  );
};

const NestedSelect = ({ label, children, parent, fieldName, onChange, required, disabled, ...rootProps }) => {
  const onFieldChange = useCallback(e => {
    onChange({ ...parent, [fieldName]: e.target.value });
  }, [onChange, parent, fieldName]);
  return (
    <Form.Group {...rootProps}>
      <Form.Label>{label}</Form.Label>
      <Form.Select value={parent[fieldName]} onChange={onFieldChange} required={required} disabled={disabled}>
        {children}
      </Form.Select>
    </Form.Group>
  );
};

export default PersonalData;