import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./DocCard.module.scss";

const DocCard = ({ doc, linkTo }) => {
  const { title, subtitle } = doc;

  return (
    <NavLink to={linkTo} className={({ isActive }) => isActive ? styles.selected : undefined}>
      <div className={styles.root + " custom-card mb-4"} style={{ minWidth: 340 }}>
        <div style={{ color: "var(--bs-primary)", fontSize: "14pt" }}>{title}</div>
        <div style={{ fontSize: '10pt' }}>{subtitle}</div>
      </div>
    </NavLink>
  );
};

export default DocCard;