import { create } from "zustand";
import call from "./call";
import { useEffect, useState } from "react";

const INDUSTRIES_URL = 'Y_4fsJs1eV';
const PROFESSIONS_URL = 'd2aFRVm5rd';
const SECTORS_URL = 'XXSG52zYV8';
const BANKS_URL = 'zoGEWNSx8W';
const COUNTRIES_URL = 'l7an6T8ycs';
const PROVINCES_URL = 'OxOopWLla7';
const NATIONALITIES_URL = '1N8caJdUD1';

const Support = {
    listIndustries: () => call(INDUSTRIES_URL).then(res => res.map(r => r.industry)),
    listProfessions: () => call(PROFESSIONS_URL).then(res => res.map(r => r.profession)),
    listSectors: () => call(SECTORS_URL).then(res => res.map(r => r.sectorPosition)),
    listBanks: () => call(BANKS_URL).then(res => res.map(r => r.bankName)),
    listCountries: () => call(COUNTRIES_URL).then(res => res.map(r => r.country)),
    listProvinces: () => call(PROVINCES_URL).then(res => res.map(r => r.stateName)),
    listNationalities: () => call(NATIONALITIES_URL).then(res => res.map(r => r.nationality))
};

const loadData = async (set, get, call, name) => {
    const value = get()[name];
    if (value) return value;
    const data = await call();
    set({ [name]: data });
    return data;
};

export const useSupportDataStore = create((set, get) => ({
    nationalities: undefined,
    provinces: undefined,
    countries: undefined,
    industries: undefined,
    professions: undefined,
    sectors: undefined,
    banks: undefined,
    loadCountries: () => loadData(set, get, Support.listCountries, 'countries'),
    loadNationalities: () => loadData(set, get, Support.listNationalities, 'nationalities'),
    loadProvinces: () => loadData(set, get, Support.listProvinces, 'provinces'),
    loadIndustries: () => loadData(set, get, Support.listIndustries, 'industries'),
    loadProfessions: () => loadData(set, get, Support.listProfessions, 'professions'),
    loadSectors: () => loadData(set, get, Support.listSectors, 'sectors'),
    loadBanks: () => loadData(set, get, Support.listBanks, 'banks')
}));

const useSupportData = (loaderName) => {
    const [data, setData] = useState([]);
    const loader = useSupportDataStore(state => state[loaderName]);
    useEffect(() => {
        loader().then(setData);
    }, [loader]);
    return data;
}

export const useCountries = () => useSupportData('loadCountries');
export const useProvinces = () => useSupportData('loadProvinces');
export const useNationalities = () => useSupportData('loadNationalities');
export const useIndustries = () => useSupportData('loadIndustries');
export const useProfessions = () => useSupportData('loadProfessions');
export const useSectors = () => useSupportData('loadSectors');
export const useBanks = () => useSupportData('loadBanks');
