import React from "react";
import SectionWrapper from "../../components/sections/SectionWrapper";
import styles from "./Projects.module.scss";
import background from "../../img/bg2.png";
import { Col, Row } from "react-bootstrap";
import ProjectCard from "../../components/cards/ProjectCard";
import { SAMPLE_PROJECTS } from "../../sample/sampleProjects";

const Projects = () => {
  return (
    <SectionWrapper id={styles.projects} background={background}>
      <div className={styles.subtitle}>Proyectos de Inversión</div>

      <Row className="gy-3 gx-3">
        {SAMPLE_PROJECTS.map((p, index) => (
          <Col xs={12} md={6} xl={3}>
            <ProjectCard {...p} />
          </Col>
        ))}
      </Row>
    </SectionWrapper>
  );
};

export default Projects;