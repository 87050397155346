import React, { useCallback, useEffect, useState } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
// import { useIMask } from 'react-imask';
// import { configMask } from "./dateUtils";

// const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
// const DATE_FORMAT = "YYYY-MM-DD";
// const TIME_FORMAT = "HH:mm";

const convertToPickerValue = (value, format) => {
  if (value) {
    return moment(value, format);
  }
};

const convertToExternalValue = (value, format) => {
  return moment(value).format(format);
}

// const FormikDateTime = ({ field, form, timeFormat, dateFormat = "DD/MM/YYYY", valueFormat = "DD-MM-YYYY", placeholder, disabled, required }) => {
//   const [pickerValue, setPickerValue] = useState(convertToPickerValue(field.value, valueFormat));

//   const onFieldChange = useCallback((value) => {
//     // let dateValue = moment(value);
//     if (value instanceof moment) {
//       // if (dateFormat && timeFormat) dateValue = moment(value).format(DATE_TIME_FORMAT);
//       // else if (dateFormat) dateValue = moment(value).format(DATE_FORMAT);
//       // else if (timeFormat) dateValue = moment(value).format(TIME_FORMAT);
//       const dateValue = convertToExternalValue(value, valueFormat);
//       console.log('setting ', value, dateValue)
//       form.setFieldValue(field.name, dateValue);
//     } else {
//       console.log('setting undefined', value);
//       form.setFieldValue(field.name, undefined);
//     }
//   }, [field.name, form, valueFormat]);
  
//   useEffect(() => {
//     setPickerValue(convertToPickerValue(field.value, valueFormat));
//   }, [field.value, valueFormat]);

//   // const mask = useMemo(() => {
//   //   let format;
//   //   if(dateFormat){
//   //     format = dateFormat;
//   //     if(timeFormat){ format = `${format} ${timeFormat}`; }
//   //   }else{
//   //     format = timeFormat;
//   //   }
    
//   //   return configMask(format, onFieldChange);
//   // }, [dateFormat, timeFormat, onFieldChange]);

//   // const { ref } = useIMask(mask);

//   const onFieldBlur = () => {
//     form.setFieldTouched(field.name, true);
//   };

//   return (
//     <Datetime
//       // value={dateFormat ? (field.value ? moment(field.value.toString()) : field.value) : field.value}
//       value={pickerValue}
//       dateFormat={dateFormat}
//       timeFormat={timeFormat}
//       onChange={onFieldChange}
//       onBlur={onFieldBlur}
//       closeOnSelect
//       inputProps={{
//         id: field.name,
//         name: field.name,
//         placeholder: placeholder,
//         disabled: disabled,
//         required: required,
//         className: "form-control form-control-sm",
//         autoComplete: "off",
//         // ref,
//       }}
//     />
//   );
// };

const FormikDateTime = ({ field, form, valueFormat, timeFormat, dateFormat, placeholder, disabled, required }) => {
  const { name, value } = field;
  const onFieldChange = useCallback((value) => {
    form.setFieldValue(name, value);
  }, [form, name]);
  const onBlur = () => {
    form.setFieldTouched(name, true);
  };
  return (
    <DateTimePicker name={name} value={value} onChange={onFieldChange} onBlur={onBlur}
      valueFormat={valueFormat} timeFormat={timeFormat} dateFormat={dateFormat}
      placeholder={placeholder} disabled={disabled} required={required} />
  );
};

export const DateTimePicker = ({ name, value, onChange, onBlur, valueFormat = "DD-MM-YYYY", timeFormat, dateFormat = "DD/MM/YYYY", placeholder, disabled, required }) => {
  const [pickerValue, setPickerValue] = useState(convertToPickerValue(value, valueFormat));
  
  const onFieldChange = useCallback((value) => {
    // let dateValue = moment(value);
    if (value instanceof moment) {
      // if (dateFormat && timeFormat) dateValue = moment(value).format(DATE_TIME_FORMAT);
      // else if (dateFormat) dateValue = moment(value).format(DATE_FORMAT);
      // else if (timeFormat) dateValue = moment(value).format(TIME_FORMAT);
      const dateValue = convertToExternalValue(value, valueFormat);
      console.log('setting ', value, dateValue)
      onChange(dateValue);
    } else {
      console.log('setting undefined', value);
      onChange(undefined);
    }
  }, [onChange, valueFormat]);

  useEffect(() => {
    setPickerValue(convertToPickerValue(value, valueFormat));
  }, [value, valueFormat]);

  return (
    <Datetime
      value={pickerValue}
      dateFormat={dateFormat}
      timeFormat={timeFormat}
      onChange={onFieldChange}
      onBlur={onBlur}
      closeOnSelect
      inputProps={{
        id: name,
        name: name,
        placeholder: placeholder,
        disabled: disabled,
        required: required,
        className: "form-control form-control-sm",
        autoComplete: "off",
        // ref,
      }}
    />
  );
};

export default FormikDateTime;
