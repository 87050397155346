import logo from "./img/adblick.png";
import "./App.scss";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";
import { useCallback, useState } from "react";

// import NotificationMenu from "./modules/notifications/NotificationMenu";
import { ProfileProvider } from "./session/profileContext";
import { saveUser } from "./session";
import ManagerModal from "./modules/home/ManagerModal";

// import 'react-date-picker/dist/DatePicker.css';
// import 'react-calendar/dist/Calendar.css';

function App() {
  const navigate = useNavigate();

  const onLogout = useCallback(() => {
    if (window.confirm("¿Desea cerrar sesión?")) {
      saveUser(undefined);
      navigate("/login");
    }
  }, [navigate]);

  const onEditProfile = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const onPasswordChange = useCallback(() => {
    navigate("/pwdchange");
  }, [navigate]);

  const [contactModal, setContactModal] = useState(false);
  const onContactClick = useCallback(() => setContactModal(true), []);
  const onContactClose = useCallback(() => setContactModal(false), []);
  // const [notifModal, setNotifModal] = useState(false);
  // const onNotifClick = useCallback(() => setNotifModal(true), []);
  // const onNotifClose = useCallback(() => setNotifModal(false), []);

  return (
    <ProfileProvider>
      <main id="adblick">
        <header>
          <div className="header-wrapper">
            <img src={logo} id="logo" alt="logo" width={140} />
            <nav>
              <NavLink to={"/"} className={({ isActive }) => isActive ? "active" : undefined}>Inicio</NavLink>
              <NavLink to={"/investments"} className={({ isActive }) => isActive ? "active" : undefined}>Mis Inversiones</NavLink>
              <NavLink to={"/projects"} className={({ isActive }) => isActive ? "active" : undefined}>Proyectos</NavLink>
              {/* <NavLink to={"/news"} className={({ isActive }) => isActive ? "active" : undefined}>Novedades</NavLink> */}
            </nav>
            <menu>
              <Button color="primary" className="me-4 px-4" onClick={onContactClick}><i className="fas fa-user-tie me-2" />Mi ejecutivo de cuenta</Button>
              <Dropdown style={{ display: 'inline-block' }}>
                <Dropdown.Toggle color="primary" className="btn-rounded me-3"><i className="fas fa-user" /></Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Dropdown.Item onClick={onEditProfile}>Mi perfil</Dropdown.Item>
                  <Dropdown.Item onClick={onPasswordChange}>Cambiar contraseña</Dropdown.Item>
                  <Dropdown.Item onClick={onLogout}>Cerrar sesión</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              
              {/* <Button color="primary" className="btn-rounded me-3" onClick={onNotifClick}><i className="fas fa-bell" /></Button> */}
            </menu>
          </div>
        </header>
        <section>
          <Outlet />
        </section>
        {contactModal && <ManagerModal onClose={onContactClose} />}
        {/* {notifModal && <NotificationMenu onClose={onNotifClose} />} */}
      </main>
    </ProfileProvider>
  );
}

export default App;
