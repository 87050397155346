import React from "react";
import styles from "./Investments.module.scss";
import { useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import InvestmentCard from "../../components/cards/InvestmentCard";
import { useEffect } from "react";
import { useCallback } from "react";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import { useParams } from "react-router-dom";
import boxTypes from "./boxTypes";
// import { SAMPLE_INVESTMENTS } from "../../sample/sampleInvestments";
import DocCard from "../../components/cards/DocCard";
import Api from "../../api";

const INITIAL_PATH = { title: "Mis Inversiones", url: "/investments" };

const COLLAPSED_COUNT = 1;

const Investments = () => {
  const [investments, setInvestments] = useState([]);
  const [displayedInvestments, setDisplayedInvestments] = useState([]);
  const [investment, setInvestment] = useState();
  const [expanded, setExpanded] = useState(false);

  const [breadcrumbPaths, setBreadcrumbPaths] = useState([]);

  useEffect(() => {
    Api.Investments.list().then(setInvestments);
  }, []);

  useEffect(() => {
    setDisplayedInvestments(expanded ? investments : investments.slice(0, COLLAPSED_COUNT))
  }, [investments, expanded]);

  const onToggleExpanded = useCallback(() => {
    setExpanded(exp => !exp);
  }, []);

  const { investmentId } = useParams();

  useEffect(() => {
    setInvestment(investmentId ? investments.find(i => i.id === investmentId) : undefined);
  }, [investments, investmentId]);

  useEffect(() => {
    const paths = [ INITIAL_PATH ];

    if (investment) {
      paths.push({
        title: investment.title,
        url: `/investments/${investment.id}`
      });
    } else {
      paths.push({
        title: "Todas mis Inversiones",
        url: "/investments"
      });
    }

    setBreadcrumbPaths(paths);
  }, [investment]);

  const BoxComponent = investment && boxTypes[investment.type];

  return (
    <section id={styles.investments}>
      <Breadcrumb paths={breadcrumbPaths} />
      <h3 className={styles.title}>Todas mis Inversiones</h3>

      <Row className="gy-3">
        {displayedInvestments.map((i, index) => (
          <Col xs={12}>
            <InvestmentCard key={index} {...i} selected={investmentId && i.id === investmentId} />
          </Col>
        ))}
      </Row>

      {investments.length > COLLAPSED_COUNT &&
        <Button variant="link" className={styles.expand} onClick={onToggleExpanded}>{expanded ? "Ver Menos" : "Ver Todos"}</Button>
      }

      <Row className={styles.detail}>
        <Col xs={12} xl={8}>
          {/* <div className="w-100"> */}
          {investment && BoxComponent && <BoxComponent title={investment.title} />}
          {/* </div> */}
        </Col>
        
        <InvestmentDocs investmentId={investmentId} />
      </Row>
    </section>
  );
};

const InvestmentDocs = ({ investmentId }) => {
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState();

  useEffect(() => {
    setLoading(true);
    Api.Docs.getInvestmentDocs(investmentId).then(setDocs).then(() => setLoading(false));
  }, [investmentId]);

  return (
    <Col className="d-flex flex-column mt-3 mt-xl-0" xs={12} xl={4}>
      {investmentId && <h4 style={{ fontSize: "14pt", borderBottom: "1px solid #7070704d" }} className="mb-4 pb-3">Documentación de Proyecto</h4>}
      {investmentId && loading && <Spinner variant="secondary" animation="border" style={{ marginTop: '0.5rem' }} />}
      {investmentId && docs && docs.map((d, i) => <DocCard key={i} doc={d} linkTo={`/investments/${investmentId}/${d.id}`} />)}
    </Col>
  );
};

export default Investments;