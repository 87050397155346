import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import styles from "./InvestmentCard.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import stockIcon from "../../img/icons/ganancia-financiera.png";
import dollarIcon from "../../img/icons/simbolo-de-dolar.png";
import calendarIcon from "../../img/icons/calendar-days.png";
import timelineIcon from "../../img/icons/timeline.png";

const InvestmentCard = ({ id, selected, title, projectTitle, investmentProduct, value, state, campaign, icon, color }) => {
  const cardStyles = useMemo(() => {
    const styles = {};
    if (selected) {
      styles.backgroundColor = color;
    }
    return styles;
  }, [color, selected]);
  return (
    <NavLink to={`/investments/${id}`} className={({ isActive }) => isActive ? styles.selected : undefined}>
      <Container className={styles.root + " custom-card " + (selected ? styles.selected : "")} style={cardStyles} fluid>
        <Row className="w-100">
          <DataCol>
            <div className={styles.icon} style={{ backgroundColor: color }}>
              <div className={styles.img} style={{ "-webkit-mask": `url("${icon}") center/contain` }} />
            </div>
            {/* <div className={styles.data}> */}
            <span className={styles.title}>{projectTitle}</span>
          </DataCol>
          <DataCol>
            <div className={styles.icon} style={{ backgroundColor: color }}>
              <div className={styles.img} style={{ "-webkit-mask": `url("${stockIcon}") center/contain` }} />
            </div>
            <span className={styles.title}>{title}</span>
          </DataCol>
          <DataCol>
            <div className={styles.icon} style={{ backgroundColor: color }}>
              <div className={styles.img} style={{ "-webkit-mask": `url("${dollarIcon}") center/contain`, width: 54, height: 54 }} />
            </div>
            <div className={styles.valueContainer}>
              <span style={{ fontWeight: 'normal' }}>Inversión actual</span>
              <span className={styles.value}>$ {value}</span>
            </div>
          </DataCol>
          <DataCol>
            <div className={styles.icon} style={{ backgroundColor: color }}>
              <div className={styles.img} style={{ "-webkit-mask": `url("${calendarIcon}") center/contain` }} />
            </div>
            <div className={styles.period}>{campaign}</div>
          </DataCol>
          <DataCol>
            <div className={styles.icon} style={{ backgroundColor: color }}>
              <div className={styles.img} style={{ "-webkit-mask": `url("${timelineIcon}") center/contain` }} />
            </div>
            <span className={styles.stateValue}>{state}</span>
          </DataCol>
        {/* </div> */}
        </Row>
      </Container>
    </NavLink>
  );
};

const DataCol = ({ children }) => {
  return (
    <Col xs={2} className="d-flex align-items-center" style={{ width: '20%' }}>
      {children}
    </Col>
  );
}

export default InvestmentCard;