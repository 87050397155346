import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import InvestmentCard from "../../components/cards/InvestmentCard";
import SectionWrapper from "../../components/sections/SectionWrapper";
import background from "../../img/bg1.png";
import "./Home.scss";

// import icGranos from "../../img/ic_granos.png";
// import icPecan from "../../img/ic_pecan.png";
// import icOlivos from "../../img/ic_olivos.png";
// import icGanaderia from "../../img/ic_ganaderia.png";
import Api from "../../api";
import { ProfileContext } from "../../session/profileContext";
import { PERSONA_JURIDICA } from "../signup/entityTypes";

// const SAMPLE = [
//   {
//     title: "Proyecto Granos -Campaña C14",
//     value: "10.000",
//     variation: 0.076,
//     period: "2020-2021",
//     color: "#1CB553",
//     icon: icGranos
//   },
//   {
//     title: "Proyecto Pecán -Campaña C11",
//     value: "10.000",
//     variation: -0.026,
//     period: "2020-2021",
//     color: "#844F16",
//     icon: icPecan
//   },
//   {
//     title: "Proyecto Olivos -Campaña C14",
//     value: "10.000",
//     variation: 0.076,
//     period: "2020-2021",
//     color: "#A09510",
//     icon: icOlivos
//   },
//   {
//     title: "Proyecto Ganadería -Campaña C14",
//     value: "10.000",
//     variation: 0.076,
//     period: "2020-2021",
//     color: "#862505",
//     icon: icGanaderia
//   },
// ];

const Home = () => {
  const [investments, setInvestments] = useState([]);
  useEffect(() => {
    // Api.Test.test();
    Api.Investments.list().then(setInvestments);
  }, []);

  const { profile } = useContext(ProfileContext);

  

  return (
    <SectionWrapper id="home" background={background}>
      <Greeting profile={profile} />

      <div className="subtitle">Accede a tus Proyectos</div>

      <Row className="gy-3">
        {investments.map((i, index) => (
        <Col xs={12} key={index}>
          <InvestmentCard key={index} {...i} />
        </Col>
        ))}
      </Row>
    </SectionWrapper>
  );
};

const Greeting = ({profile}) => {
  if (profile) {
    const pj = profile.entityType === PERSONA_JURIDICA;
    if (pj) {
      return (
        <h3>Bienvenidos,<br/>{profile.businessName}</h3>
      );
    } else {
      return (
        <h3>
          Bienvenido{profile.investorName && ','}{profile && profile.investorName && <br/>}
          {profile.investorName && `${profile.investorName} ${profile.investorLastName}`}
        </h3>
      );
    }
  } else {
    return <h3>Bienvenido</h3>;
  }
};

export default Home;