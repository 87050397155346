import React from "react";
import styles from "./ProjectNews.module.scss";

const ProjectNews = () => {
  return (
    <div className={styles.root}>
      
    </div>
  );
};

export default ProjectNews;