// import jwt from "jsonwebtoken";

export const getUser = () => {
    const user = localStorage.getItem("adblick-user");
    if (user) return JSON.parse(user);
};

export const saveUser = (user) => {
    if (user) {
        saveExpiration(user);
        localStorage.setItem("adblick-user", JSON.stringify(user));
    } else {
        removeExpiration();
        localStorage.removeItem("adblick-user");
    }
}

export const getToken = () => {
    const user = getUser();
    if (user) {
        return user.token;
    }
}

const saveExpiration = (user) => {
    const { token } = user;
    const payloadJson = token.split(".")[1];
    const payload = JSON.parse(atob(payloadJson));
    localStorage.setItem("adblick-token-exp", `${payload.exp}`);
}
const removeExpiration = () => {
    localStorage.removeItem("adblick-token-exp");
}

export const isTokenExpired = () => {
    const cachedExp = localStorage.getItem("adblick-token-exp");
    if (cachedExp) {
        try {
            const exp = Number(cachedExp);
            return Date.now() >= exp * 1000;
        } catch (err) {
            return true;
        }
    }
    if (getUser()) {
        return true;
    }
    return false;
}