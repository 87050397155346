import call from "./call";

const INV_DOCS_URL = 'dP2F01kvZr?investmentProduct=';
const FILE_URL = 'hG5Y2MPMXy?fileId=';
//const PROJECT_DOCS_URL = 'dN6zgPJ6bk?investmentProduct=';
const PROJECT_DOCS_URL = 'smRwbwt9XN?investmentProject=';
const PROJECT_VIDEOS_URL = 'IwXyt3Q4n0?investmentProduct=';

const convertFileToDoc = file => ({
    id: file.fileId,
    title: file.type,
    subtitle: file.fileName
});

const Docs = {
    getInvestmentDocs: (invId) => call(`${INV_DOCS_URL}${invId}`).then(docs => docs.map(convertFileToDoc)),
    getFileUrl: (fileId) => call(`${FILE_URL}${fileId}`).then(results => results && results.length > 0 && results[0]),
    getProjectDocs: (projectId) => call(`${PROJECT_DOCS_URL}${projectId}`)
        .then(docs => docs.map(doc => ({ ...doc, id: doc.fileId, title: doc.fileName }))),
    getProjectVideos: (projectId) => call(`${PROJECT_VIDEOS_URL}${projectId}`)
};

export default Docs;