import { callFile } from "./call";

const Files = {
    upload: (file) => callFile('storage', 'POST', {
            completeName: file.name,
            size: file.size
        }).then(res => {
            const { fileId, url } = res;
            const formData = new FormData();
            formData.append("file", file, file.name);
            return fetch(url, {
                method: 'POST',
                body: formData
            }).then(res => {
                if (res.status === 204) {
                    return ({
                        completeName: file.name,
                        corporate: true,
                        id: fileId,
                        fileId
                    });
                } else {
                    console.error('Error uploading file: ' + res.text());
                    throw new Error(`Error al adjuntar archivo: ${file.name}`);
                }
            });
        }),
    commit: (fileObject) => callFile('edna/File', 'POST', fileObject)
};

export default Files;