import React, { useCallback, useState } from "react";
// import Attachments from "./Attachments";
import BankData from "./BankData";
import PersonalData from "./PersonalData";

import "./SignUp.scss";
import TaxData from "./TaxData";
import WorkData from "./WorkData";

import emailVerifyImg from "../../img/email-verify.png";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import EntityTypeSelection from "./EntityTypeSelection";
import Api from "../../api";

const SignUp = () => {

  const [currentStep, setCurrentStep] = useState(1);
  const [signupFinished, setSignupFinished] = useState(false);

  const onNext = useCallback(() => {
    setCurrentStep(s => {
      if (s < STEPS.length) {
        return s + 1;
      }
      return s;
    })
  }, []);

  const onPrevious = useCallback(() => {
    setCurrentStep(s => {
      if (s > 1) {
        return s - 1;
      }
      return s;
    })
  }, []);

  const [values, setValues] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const onFinish = useCallback((lastValues) => {
    // localStorage.setItem("adblick-user", JSON.stringify({ user: 'test' }));

    const { bankName, cbuCvu, accountNumber, ...lastRest } = lastValues;

    const { legalRepresentative, controllingEntity, ...rest } = values;

    const finalValues = { ...rest, ...lastRest };

    finalValues.bankInformation = [ { bankName, cbuCvu, accountNumber } ];
    if (legalRepresentative) finalValues.legalRepresentative = [ legalRepresentative ];
    if (controllingEntity) finalValues.controllingEntity = [ controllingEntity ];

    // were specified as boolean initially
    // finalValues.conditionIVA = finalValues.conditionIVA === 'RESPONSABLE INSCRIPTO';
    // finalValues.profits = finalValues.profits === 'INSCRIPTO';
    // finalValues.personalProperty = finalValues.personalProperty === 'INSCRIPTO';

    setSubmitting(true);
    Api.Profile.create(finalValues).then(res => {
      console.log('signup response', res);
      setSubmitting(false);
      setSignupFinished(true);
    }).catch(err => {
      setSubmitting(false);
      console.log('error on signup', err);
    });
  }, [values]);

  
  const onUpdateValues = useCallback(values => {
    setValues(current => ({ ...current, ...values }));
  }, []);

  const stepData = STEPS[currentStep - 1];
  const { Component } = stepData;

console.log('current values', values)

  return (
    <main id="signup">
      <header>
        <h3>Registro de Nuevo Usuario</h3>
        <p className="subtitle">Siga los pasos y complete los campos con los datos requeridos</p>
      </header>

      {!signupFinished &&
        <div className="signup-form">
          <SignUpStepGuide currentStep={currentStep} />

          {Component && <Component step={currentStep} totalSteps={STEPS.length}
                                   submitting={submitting} 
                                   values={values} onUpdateValues={onUpdateValues}
                                   onNext={onNext} onPrevious={onPrevious} onFinish={onFinish}
                                   {...stepData} />}
        </div>
      }
      {signupFinished &&
        <SignUpConfirm />
      }
    </main>
  );
};

const STEPS = [
  {
    title: 'Paso 1',
    subtitle: 'Tipo de Entidad',
    icon: 'fas fa-person',
    Component: EntityTypeSelection
  },
  {
    title: 'Paso 2',
    subtitle: 'Datos Personales',
    icon: 'fas fa-id-card',
    Component: PersonalData
  },
  {
    title: 'Paso 3',
    subtitle: 'Datos Laborales',
    icon: 'fas fa-briefcase',
    Component: WorkData
  },
  {
    title: 'Paso 4',
    subtitle: 'Datos Fiscales y Patrimoniales',
    icon: 'fas fa-hand-holding-dollar',
    Component: TaxData
  },
  {
    title: 'Paso 5',
    subtitle: 'Información Bancaria',
    icon: 'fas fa-building-columns',
    Component: BankData
  },
  // {
  //   title: 'Paso 6',
  //   subtitle: 'Documentos adjuntos',
  //   icon: 'fas fa-upload',
  //   Component: Attachments
  // }
];

const SignUpStepGuide = ({ currentStep = 1 }) => {
  return (
    <div className="signup-step-guide">
      {STEPS.map((step, index) => (
        <>
          <SignUpStep key={index} {...step} completed={index <= currentStep - 1} />
          {index < STEPS.length - 1 && <div className={`step-line ${index < currentStep - 1 ? 'completed' : ''}`} />}
        </>
      ))}
    </div>
  );
};

const SignUpStep = ({ title, subtitle, icon, completed }) => {
  return (
    <div className={`signup-step ${completed ? 'completed' : ''}`}>
      <div className="step-text">
        <p className="step-title">{title}</p>
        <p className="step-subtitle">{subtitle}</p>
      </div>
      <div className="step-icon">
        <i className={icon} />
      </div>
    </div>
  );
};

const SignUpConfirm = () => {
  const onHome = useCallback(() => {

  }, []);

  return (
    <div className="signup-confirm">
      <img src={emailVerifyImg} alt="Verify your email" />
      <div className="signup-email">
        <h3 className="subtitle">Verificación de email</h3>
        <p>
          Gracias por registrarte en el portal de usuarios de Adblick.<br />
          Se ha enviado un correo a tu mail para validar tu cuenta, sigue el enlace para que puedas concluir el proceso.
        </p>
        <Link to={`/`}>
          <Button variant="primary" className="mt-3" onClick={onHome}>Ir a inicio</Button>
        </Link>
      </div>
    </div>
  );
};

export default SignUp;