import React, { useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DataForm from "./DataForm";
import { Field } from "formik";
// import Api from "../../api";
import Select from "../../components/select/Select";
import { useBanks } from "../../api/support";

export const bankDataInitialValues = {
  bankName: '',
  cbuCvu: '',
  accountNumber: ''
};

const BankData = ({ values, ...stepProps }) => {
  const initialValues = useMemo(() => ({
    ...bankDataInitialValues,
    ...values
  }), [values]);

  return (
    <DataForm {...stepProps} initialValues={initialValues}>
      <BankDataFormInputs />
    </DataForm>
  );
};

export const BankDataFormInputs = ({ isSubmitting }) => {
  const banks = useBanks();

  return (
    <>
      <Form.Group className="mb-3" controlId="bankName">
        <Form.Label>Banco</Form.Label>
        <Select name="bankName" disabled={isSubmitting}>
          <option>Banco</option>
          {banks.map((i, k) => (<option key={k} value={i}>{i}</option>))}
        </Select>
      </Form.Group>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="cbuCvu">
          <Form.Label>CBU | CVU</Form.Label>
          <Form.Control type="text" name="cbuCvu" placeholder="CBU | CVU" disabled={isSubmitting} as={Field} />
        </Form.Group>
        <Form.Group as={Col} controlId="accountNumber">
          <Form.Label>Nro. de Cuenta</Form.Label>
          <Form.Control type="text" name="accountNumber" placeholder="Nro. de Cuenta" disabled={isSubmitting} as={Field} />
        </Form.Group>
      </Row>
    </>
  );
};

export default BankData;