import coverGranos from "../img/cover_granos.png";
// import coverPecan from "../img/cover_pecan.png";
import coverOlivos from "../img/cover_olivos.png";
import coverGanaderia from "../img/cover_ganaderia.png";
import coverCampos from "../img/cover_campos.png";
import coverHidroponia from "../img/cover_hidroponia.png";

export const SAMPLE_PROJECTS = [
    {
        id: "GRANOS",
        title: "Adblick Granos",
        date: "Junio 2021",
        coverImage: coverGranos
    },
    {
        id: "AGRO",
        title: "Adblick Agro",
        date: "Junio 2021",
        coverImage: coverCampos
    },
    {
        id: "OLIVOS I",
        title: "Adblick Olivos",
        date: "Junio 2021",
        coverImage: coverOlivos
    },
    {
        id: "GANADERÍA",
        title: "Adblick Ganadería",
        date: "Junio 2021",
        coverImage: coverGanaderia
    },
    {
        id: "CAMPOS I",
        title: "Adblick Campos",
        date: "Junio 2021",
        coverImage: coverCampos
    },
    {
        id: "HIDROPONIA",
        title: "Adblick Hidroponia",
        date: "Junio 2021",
        coverImage: coverHidroponia
    }
];