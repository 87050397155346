import call, { callAuthEvent } from "./call";
import moment from "moment";

const PROFILE_URL = `GX7uUCmxh6`
const EVENT_URL = `kNoyY1n2gh`;

const Profile = {
    get: () => call(`${PROFILE_URL}`).then(profiles => profiles && profiles.length && profiles[0])
        .then(profile => {
            if (profile) {
                const { bankInformation = [], legalRepresentative = [], controllingEntity = [] } = profile;
                const bank = bankInformation.length > 0 ? bankInformation[0] : {};
                const legal = legalRepresentative.length > 0 ? legalRepresentative[0] : {};
                const control = controllingEntity.length > 0 ? controllingEntity[0] : {};

                const { bankName, cbuCvu, accountNumber } = bank;

                if (legal.birthday) {
                    legal.birthday = moment(legal.birthday).format('DD-MM-YYYY');
                }
                if (legal.roleEndDate) {
                    legal.roleEndDate = moment(legal.roleEndDate).format('DD-MM-YYYY');
                }
                
                return ({
                    ...profile,
                    birthday: moment(profile.birthday).format('DD-MM-YYYY'),
                    bankName, cbuCvu, accountNumber,
                    legalRepresentative: legal,
                    controllingEntity: control,
                    document: Array.isArray(profile.document) ? profile.document : [profile.document]
                    // were specified as boolean initially
                    // conditionIVA: profile.conditionIVA ? 'RESPONSABLE INSCRIPTO' : 'NO INSCRIPTO',
                    // profits: profile.profits ? 'INSCRIPTO' : 'NO INSCRIPTO',
                    // personalProperty: profile.personalProperty ? 'INSCRIPTO' : 'NO INSCRIPTO'
                })
            }
        }),
    create: (data) => call(EVENT_URL, 'POST',
        {
            message: 'events.adblick.ib_create.prospect',
            ...data
        }),
    update: (data) => callAuthEvent('event/flow/message', 'POST', {
        ...data,
        message: 'events.adblick.ib_edit.investor.front'
    })
};

export default Profile;