import { create } from "zustand";

export const useDocumentStore = create((set) => ({
    documents: [],
    setDocuments: (docs) => set(({ documents: docs })),
    deleteDoc: (id) => set(state => {
        const newDocs = [...state.documents];
        const doc = newDocs.find(d => d.fileId === id);
        doc.markedToDelete = true;
        return ({ documents: newDocs });
    }),
    restoreDoc: (id) => set(state => {
        const newDocs = [...state.documents];
        const doc = newDocs.find(d => d.fileId === id);
        doc.markedToDelete = false;
        return ({ documents: newDocs });
    })
}));