import React, { useCallback, useEffect, useState } from "react";
import SectionWrapper from "../../components/sections/SectionWrapper";
import { Alert, Button, Col, Row, Spinner } from "react-bootstrap";
import "./Profile.scss";
import { PersonalDataFormInputs, personalDataInitialValues } from "../signup/PersonalData";
import { WorkDataFormInputs, workDataInitialValues } from "../signup/WorkData";
import { TaxDataFormInputs, taxDataInitialValues } from "../signup/TaxData";
import { BankDataFormInputs, bankDataInitialValues } from "../signup/BankData";
import { Link, Element } from "react-scroll";
import { Form, Formik } from "formik";
import Api from "../../api";
import { useNavigate } from "react-router-dom";
import Attachments from "./Attachments";
import { useFileStore } from "./fileStore";
import { shallow } from "zustand/shallow";
import { useDocumentStore } from "./documentStore";
import { PERSONA_JURIDICA } from "../signup/entityTypes";

const Profile = () => {
  const nav = useNavigate();
  const onClose = useCallback(() => {
    nav('/');
  }, [nav]);

  const [form, setForm] = useState(false);
  const [valuesPendingSubmission, setValuesPendingSubmission] = useState();
  const [lastSubmitted, setLastSubmitted] = useState();
  const [submitError, setSubmitError] = useState();

  const [files, fileObjects, startUpload, markUploadsReady, uploading,
    allSuccessful, setUploadSuccess, setUploadFailure, clear] = useFileStore(state => [
    state.files, state.uploadedFileObjects, state.startUpload, state.markUploadsReady, state.uploading,
    state.allSuccessful, state.setUploadSuccess, state.setUploadFailure, state.clear
  ], shallow);

  const [documents, setDocuments] = useDocumentStore(state => [state.documents, state.setDocuments], shallow);

  const save = useCallback(() => {
    const { bankName, cbuCvu, accountNumber, legalRepresentative, controllingEntity, ...finalValues } = valuesPendingSubmission;

    finalValues.bankInformation = [ { bankName, cbuCvu, accountNumber } ];
    if (legalRepresentative) finalValues.legalRepresentative = [ legalRepresentative ];
    if (controllingEntity) finalValues.controllingEntity = [ controllingEntity ];

    // were specified as boolean initially
    // finalValues.conditionIVA = finalValues.conditionIVA === 'RESPONSABLE INSCRIPTO';
    // finalValues.profits = finalValues.profits === 'INSCRIPTO';
    // finalValues.personalProperty = finalValues.personalProperty === 'INSCRIPTO';

    finalValues.document = [...(documents.filter(d => !d.markedToDelete).map(f => f.fileId)), ...(fileObjects.map(f => f.fileId))];

    console.log('values', finalValues);
    setSubmitError(undefined);
    Api.Profile.update(finalValues).then(res => {
      console.log('response event', res);
      setValuesPendingSubmission(undefined); // prevent resending values
      setLastSubmitted(Date.now()); // reload profile data 
      form.setSubmitting(false);
    }).catch(err => {
      console.error('error event', err);
      setSubmitError(err.message);
    })
  }, [valuesPendingSubmission, form, fileObjects, documents]);

  // check if uploads are finished after form is submitted
  useEffect(() => {
    if (!valuesPendingSubmission) return;

    if (fileObjects.length === 0 || (!uploading && allSuccessful)) {
      // save
      save();
      return;
    }
  }, [save, valuesPendingSubmission, fileObjects, uploading, allSuccessful]);

  // clear uploaded files data after submission
  useEffect(() => {
    if (!valuesPendingSubmission) {
      clear();
    }
  }, [clear, valuesPendingSubmission]);

  // perform uploads when marked ready
  useEffect(() => {
    // check upload ready and not uploading
    fileObjects.forEach((o, i) => {
      if (o.uploadReady && !o.uploading) {
        startUpload(i);
        Api.Files.upload(files[i])
          .then(res => {
            Api.Files.commit(res).then(r => {
              setUploadSuccess(i, r);
            }).catch(err => {
              setUploadFailure(i, err.message);
            });
          }).catch(err => {
            setUploadFailure(i, err.message);
          });
      }
    });
  }, [fileObjects, startUpload, files, setUploadFailure, setUploadSuccess]);

  const onSubmit = useCallback((values, form) => {
    setValuesPendingSubmission(values);
    setForm(form);
    markUploadsReady();
  }, [markUploadsReady]);

  const [existingProfile, setExistingProfile] = useState();
  useEffect(() => {
    Api.Profile.get().then(profile => {
      setExistingProfile({
        ...initialValues,
        ...profile
      });
    });
  }, [lastSubmitted]);

  useEffect(() => {
    if (existingProfile) {
      setDocuments(existingProfile.document || []);
    }
  }, [existingProfile, setDocuments]);

  const pj = existingProfile && (existingProfile.entityType === PERSONA_JURIDICA);

  return (
    <SectionWrapper id="profile" bannerHeight={320}>
      {!existingProfile &&
      <Row style={{ paddingTop: 120 }}>
        <Col xs={12} className="d-flex justify-content-center">
          <Spinner variant="primary" animation="border" role="status" />
        </Col>
      </Row>
      }
      {existingProfile &&
        <Row style={{ paddingTop: 20 }}>
          <Col lg={3} className="d-none d-lg-block">
            <div className="d-flex flex-column align-items-center profile-menu">
              <div className="picture"><i className="fas fa-user" /></div>
              <div className="name">{pj ? existingProfile.businessName : `${existingProfile.investorName} ${existingProfile.investorLastName}`}</div>
              {STEPS.map((s, i) => (
                <Link key={i} containerId="form-container" className="section-nav" activeClass="active" smooth spy to={s.id}>{s.subtitle}</Link>
              ))}
            </div>
          </Col>
          <Col xs={12} lg={9} style={{ height: 760, paddingTop: 60 }}>
            <Formik initialValues={existingProfile} onSubmit={onSubmit}>
              {({ isSubmitting }) => (
                <Form>
                  <div className="d-flex flex-column form-container" id="form-container">
                    {STEPS.map((s, i) => {
                      const Component = s.Component;
                      return (
                        <Element className="section-form" id={s.id}>
                          <h3>{s.subtitle}</h3>
                          {Component && <Component {...s} isSubmitting={isSubmitting} entityType={existingProfile.entityType} />}
                        </Element>
                      );
                    })}
                  </div>
                  {lastSubmitted && !isSubmitting && !submitError &&
                    <Alert variant="info">Los cambios a su perfil se guardaron exitosamente.</Alert>
                  }
                  {submitError &&
                    <Alert variant="danger">Ocurrió un error al guardar su perfil: {submitError}</Alert>
                  }
                  <div className="form-footer">
                    <div className="required-legend">
                      {"* Campos de requerimiento obligatorio."}
                    </div>
                    <Button variant="link" onClick={onClose} disabled={isSubmitting}>Cerrar</Button>
                    <Button variant="primary" type="submit" className="ms-5" disabled={isSubmitting} style={{ width: 120 }}>
                      {isSubmitting ? <Spinner variant="light" size="sm" animation="border" role="status" /> : 'Modificar'}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      }
    </SectionWrapper>
  );
};

const STEPS = [
  {
    id: 'personal-data',
    subtitle: 'Datos Personales',
    icon: 'fas fa-person',
    Component: PersonalDataFormInputs,
    initialValues: personalDataInitialValues,
    editing: true
  },
  {
    id: 'work-data',
    subtitle: 'Datos Laborales',
    icon: 'fas fa-briefcase',
    Component: WorkDataFormInputs,
    initialValues: workDataInitialValues
  },
  {
    id: 'tax-data',
    subtitle: 'Datos Fiscales y Patrimoniales',
    icon: 'fas fa-hand-holding-dollar',
    Component: TaxDataFormInputs,
    initialValues: taxDataInitialValues
  },
  {
    id: 'bank-data',
    subtitle: 'Información Bancaria',
    icon: 'fas fa-building-columns',
    Component: BankDataFormInputs,
    initialValues: bankDataInitialValues
  },
  {
    id: 'documents',
    subtitle: 'Documentos adjuntos',
    icon: 'fas fa-upload',
    Component: Attachments
  }
];

const initialValues = STEPS.reduce((values, step) => ({
  ...values,
  ...(step.initialValues)
}), {});

export default Profile;