import React, { useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DataForm from "./DataForm";
import { Field } from "formik";
import { PERSONA_JURIDICA } from "./entityTypes";
import Select from "../../components/select/Select";

export const taxDataInitialValues = {
  conditionIVA: 'RESPONSABLE INSCRIPTO',
  profits: 'INSCRIPTO',
  personalProperty: 'INSCRIPTO',
  netWorth: 0,
  monthlyIncome: 0
};

const TaxData = ({ values, ...stepProps }) => {
  const initialValues = useMemo(() => ({
    ...taxDataInitialValues,
    ...values
  }), [values]);

  return (
    <DataForm {...stepProps} initialValues={initialValues}>
      <TaxDataFormInputs entityType={values.entityType} />
    </DataForm>
  );
};

export const TaxDataFormInputs = ({ entityType, isSubmitting }) => {
  const pj = entityType === PERSONA_JURIDICA;

  return (
    <>
      <Form.Group className="mb-3" controlId="conditionIVA">
        <Form.Label>Condición frente al IVA</Form.Label>
        <Select name="conditionIVA" disabled={isSubmitting}>
          <option>Condición frente al IVA</option>
          <option value="CONSUMIDOR FINAL">Consumidor final</option>
          <option value="MONOTRIBUTISTA">Monotributista</option>
          <option value="RESPONSABLE INSCRIPTO">Responsable inscripto</option>
          <option value="EXENTO">Exento</option>
        </Select>
      </Form.Group>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="profits">
          <Form.Label>Condición Ganancias</Form.Label>
          <Select name="profits" disabled={isSubmitting}>
            <option>Condición Ganancias</option>
            <option value="INSCRIPTO">Inscripto</option>
            <option value="NO INSCRIPTO">No inscripto</option>
          </Select>
        </Form.Group>
        <Form.Group as={Col} controlId="personalProperty">
          <Form.Label>Condición Bienes Personales</Form.Label>
          <Select name="personalProperty" disabled={isSubmitting}>
            <option>Condición Bs. Personales</option>
            <option value="INSCRIPTO">Inscripto</option>
            <option value="NO INSCRIPTO">No inscripto</option>
          </Select>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="netWorth">
          <Form.Label>Patrimonio neto aprox.</Form.Label>
          <Form.Control type="number" name="netWorth" placeholder="Patrimonio neto aprox." disabled={isSubmitting} as={Field} />
        </Form.Group>
        <Form.Group as={Col} controlId="monthlyIncome">
          <Form.Label>Ingreso mensual aprox.</Form.Label>
          <Form.Control type="number" name="monthlyIncome" placeholder="Ingreso mensual aprox." disabled={isSubmitting} as={Field} />
        </Form.Group>
      </Row>
      {pj &&
      <>
        <Form.Group className="mb-3" controlId="annualTurnover">
          <Form.Label>Volumen de Negocios Anual</Form.Label>
          <Form.Control type="number" name="annualTurnover" disabled={isSubmitting} as={Field} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="accountCloseDate">
          <Form.Label>Fecha de Cierre de Cuenta</Form.Label>
          <Form.Control type="text" name="accountCloseDate" disabled={isSubmitting} as={Field} />
        </Form.Group>
      </>
      }
    </>
  );
};

export default TaxData;