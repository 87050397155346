import React from "react";

import "./SectionBanner.scss";
import { Carousel } from "react-bootstrap";

import bg1 from "../../img/slides/bg1.png";
import bg2 from "../../img/slides/bg2.png";
import bg3 from "../../img/slides/bg3.png";
import bg4 from "../../img/slides/bg4.png";
import bg5 from "../../img/slides/bg5.png";

const IMAGES = [
  bg1, bg2, bg3, bg4, bg5
];

const SectionBanner = ({ children, height }) => {
  const style = {};
  if (height) {
    style.height = height;
  }
  return (
    <div className="banner-container" style={style}>
      <Carousel controls={false} indicators={false} interval={6000} fade className="banner">
        {IMAGES.map((img, i) => (
          <Carousel.Item key={i}>
            <img src={img} className="d-block w-100" alt={"slide " + i} />
          </Carousel.Item>
        ))}
      </Carousel>
      {/* <div className="banner" style={{ backgroundImage: `url(${image})` }} /> */}
      <div className="banner-overlay" />
      {children}
    </div>
  );
};

export default SectionBanner;