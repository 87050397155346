import Investments from "./investments";
import Session from "./session";
import Profile from "./profile";
import Docs from "./docs";
import Manager from "./manager";
import Test from "./test";
import Files from "./files";

const Api = {
    Investments,
    Profile,
    Session,
    Docs,
    Manager,
    Files,
    Test
};

export default Api;