import React, { useMemo } from "react";
import DataForm from "./DataForm";
import { Col, Row } from "react-bootstrap";
import { Field } from "formik";
import "./EntityTypeSelection.scss";
import { PERSONA_FISICA, PERSONA_JURIDICA } from "./entityTypes";

const EntityTypeSelection = ({ values, ...stepProps }) => {
  const initialValues = useMemo(() => ({
    entityType: values.entityType || PERSONA_FISICA
  }), [values]);
  
  return (
    <DataForm {...stepProps} initialValues={initialValues} className="entity-type">
      <Field name="entityType">
        {({ field, form }) => (
          <Row>
            <Col xs={6}>
              <label className={`entity-type-option p-5 ${field.value === PERSONA_FISICA ? "checked border border-2 border-primary rounded " : ""}`}>
                <input type="radio" {...field} value={PERSONA_FISICA} checked={field.value === PERSONA_FISICA} />
                <i className="fas fa-person" />
                Persona Física
              </label>
            </Col>
            <Col xs={6}>
              <label className={`entity-type-option p-5 ${field.value === PERSONA_JURIDICA ? "checked border border-2 border-primary rounded" : ""}`}>
                <input type="radio" {...field} value={PERSONA_JURIDICA} checked={field.value === PERSONA_JURIDICA} />
                <i className="fas fa-building" />
                Persona Jurídica
              </label>
            </Col>
          </Row>
        )}
      </Field>
    </DataForm>
  );
};

export default EntityTypeSelection;