import { getToken, isTokenExpired, saveUser } from "../session";

const BASE_URL = 'https://s5k.io/';
// const BASE_URL = 'https://candidate-api.sitrack.io/';
// const BASE_URL = 'https://api.sitrack.io/';

const AUTH_EVENT_BASE_URL = `https://api.sitrack.io/`;
const FILE_BASE_URL = `https://api.sitrack.io/`;

const call =  (path, method = 'GET', body, headers) => {
   return callWithBase(BASE_URL, path, method, body, headers);
};

export const callAuthEvent = (path, method = 'GET', body, headers) => {
   return callWithBase(AUTH_EVENT_BASE_URL, path, method, body, {
      ...headers,
      'Content-Type': 'application/json'
   });
};

export const callFile = (path, method = 'GET', body, headers) => {
   return callWithBase(FILE_BASE_URL, path, method, body, headers);
};

const callWithBase =  (baseUrl, path, method = 'GET', body, headers) => {
   if (isTokenExpired()) {
      saveUser(undefined);
      window.location.replace('/login');
      return Promise.reject();
   }

    const authHeaders = getAuthHeaders();
   //  const contentTypeHeader = body ? { 'Content-Type': 'application/json' } : {};
    return fetch(`${baseUrl}${path}`, {
        method,
        body: body ? JSON.stringify(body) : undefined,
      //   credentials: 'include',
        headers: {
            // ...contentTypeHeader,
            ...authHeaders,
            ...(headers || {})
        }
    }).then(res => {
        if (!res.ok) {
            if (res.status === 401) { //Unauthorized
               window.location.replace(
                  `/login`
               );
            } else {
               return res.json().then(json => {
                  throw new Error(extractError(json));
               });
            }
         } else {
            if (res.status === 204) {
                return res;
             } else {
                return res.json();
             }
         }
    });
};

const getAuthHeaders = () => {
    const token = getToken();
    if (token) {
        return {
            Authorization: `Bearer ${token}`
        };
    } else {
        return {};
    }
}

const extractError = (json) => {
    if (json.tags) {
       let messages = "";
       for (var tag in json.tags) {
          messages += json.tags[tag] + "\n";
       }
       return messages;
    } else {
       return JSON.stringify(json);
    }
 };

export default call;