import React, { useCallback, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { Formik, Form as FormikForm, Field } from "formik";
import "./Login.scss";
import Api from "../../api";
import { saveUser } from "../../session";
import logo from "../../img/adblick.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

const loginInitialValues = {
  dni: '',
  password: ''
}
const resetPasswordInitialValues = {
  cuit: '',
  email: ''
}

const Login = () => {
  const location = useLocation();

  return (
    <main id="login">
      <div className="login-image">
        {/* <div className="overlay" /> */}
      </div>
      <div className="login-form">
        <img id="logo" src={logo} alt="Adblick agro" width={220} />
        <h3>Bienvenido</h3>
        {location.pathname === "/login" && <LoginForm />}
        {location.pathname === "/recover" && <ResetPasswordForm />}
        {location.pathname === "/resetconfirm" && <ResetPasswordConfirm />}
      </div>
    </main>
  );
};

const LoginForm = () => {
  const [error, setError] = useState();

  const onLogin = useCallback((values, { setSubmitting }) => {
    if (values.dni && values.password) {
      Api.Session.login(values.dni, values.password).then(user => {
        saveUser(user);
        setError();
        window.location.replace('/');
      }).catch(err => {
        setError(err);
        setSubmitting(false);
      });
    }
  }, []);

  return (
    <>
      <h5>Iniciar sesión</h5>

      <Formik initialValues={loginInitialValues} onSubmit={onLogin}>
        {({ isSubmitting }) => (
          <FormikForm>
            <Form.Group className="mb-3" controlId="dni">
              <Form.Label>Usuario</Form.Label>
              <Field as={Form.Control} type="text" placeholder="Usuario" name="dni" disabled={isSubmitting} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Contraseña</Form.Label>
              <Field as={Form.Control} type="password" placeholder="Contraseña" name="password" disabled={isSubmitting} />
            </Form.Group>
            <div className="mb-3 d-flex justify-content-end">
              <Link to="/recover">Recuperar contraseña</Link>
            </div>
            <div className="mb-3 d-flex">
              {error && !isSubmitting && <Alert variant="danger" className="flex-grow-1">{error.message}</Alert>}
            </div>
            <Button variant="primary" type="submit" className="mb-3" disabled={isSubmitting} style={{ color: 'white' }}>
              {isSubmitting ?
                <Spinner as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true" /> :
                'Iniciar Sesión'
              }
            </Button>
            <div className="d-flex fw-bold">
              Si no tenés cuenta por favor <Link to="/signup" className="ms-1">Registrate</Link>
            </div>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};

const ResetPasswordForm = () => {
  const [error, setError] = useState();

  const nav = useNavigate();

  const onResetPassword = useCallback((values, { setSubmitting }) => {
    console.log('submit', values);
    if (values.cuit && values.email) {
      const cuit = Number(values.cuit);
      if (isNaN(cuit)) {
        setError(new Error("Ingrese su CUIT sin guiones"));
        setSubmitting(false);
        return;
      }

      console.log(Api.Session);
      Api.Session.resetPassword(cuit, values.email).then(() => nav('/resetconfirm'))
        .catch(err => {
          console.error(err);
          setError(err);
          setSubmitting(false);
          nav('/resetconfirm')
        });
    } else {
      setError(new Error("Ingrese CUIT y su email"));
    }
  }, [nav]);

  return (
    <>
      <h5>Restablecer contraseña</h5>

      <Formik initialValues={resetPasswordInitialValues} onSubmit={onResetPassword}>
        {({ isSubmitting }) => (
          <FormikForm>
            <Form.Group className="mb-3" controlId="cuit">
              <Form.Label>CUIT (sólo números, sin guiones)</Form.Label>
              <Field as={Form.Control} type="number" placeholder="CUIT" name="cuit" disabled={isSubmitting} required />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Field as={Form.Control} type="email" placeholder="Email" name="email" disabled={isSubmitting} required />
            </Form.Group>
            <div className="mb-3 d-flex justify-content-end">
              <Link to="/login">Iniciar sesión</Link>
            </div>
            
            <div className="mb-3 d-flex">
              {error && !isSubmitting && <Alert variant="danger" className="flex-grow-1">{error.message}</Alert>}
            </div>
            <Button variant="primary" type="submit" className="mb-3" disabled={isSubmitting} style={{ color: 'white' }}>
              {isSubmitting ?
                <Spinner as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true" /> :
                'Enviar'
              }
            </Button>
            <div className="d-flex fw-bold">
              Si no tenés cuenta por favor <Link to="/signup" className="ms-1">Registrate</Link>
            </div>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};

const ResetPasswordConfirm = () => (
  <>
    <h5>Solicitud enviada</h5>

    <p>Por favor revise su correo electrónico y siga las instrucciones para volver a ingresar al sistema.</p>
    <p><Link to="/login">Volver al inicio</Link></p>
  </>
);

export default Login;