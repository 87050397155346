import { createContext, useEffect, useState } from "react";
import Api from "../api";

const ProfileContext = createContext();

const ProfileProvider = ({ children }) => {
    const [profile, setProfile] = useState({ profile: {} });
    useEffect(() => {
        Api.Profile.get().then(profile => setProfile({ profile }));
    }, []);

    return (
        <ProfileContext.Provider value={profile}>
            {children}
        </ProfileContext.Provider>
    );
};

export { ProfileContext, ProfileProvider };