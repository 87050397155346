import React, { useState, useEffect, useCallback } from "react";
import Api from "../../api";
import { Alert, Button, Spinner } from "react-bootstrap";
import { Document, Page } from "react-pdf";
import styles from "./DocumentRenderer.module.scss";
import "./pdf.scss";

const DocumentRenderer = ({ doc }) => {
  const [fileUrl, setFileUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { title, id: docId } = doc;

  useEffect(() => {
    if (docId) {
      setLoading(true);
      setError(undefined);
      Api.Docs.getFileUrl(docId).then(file => setFileUrl(file.url))
        .then(() => setError(undefined))
        .then(() => setLoading(false))
        .catch(setError);
    } else {
      setFileUrl(undefined);
    }
  }, [docId]);

  const [numPages, setNumPages] = useState(null);
  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
  }, []);

  const onDownload = useCallback(() => {
    if (fileUrl) {
      window.open(fileUrl, '_blank');
    }
  }, [fileUrl]);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex">
        <h4 style={{ fontSize: "14pt", flex: 1 }}>{title}</h4>
        {!loading && fileUrl && <Button className="px-5" onClick={onDownload}>Descargar</Button>}
      </div>
      <div className="mt-2">
        {error && <Alert variant="danger" className="flex-grow-1">Error cargando el archivo: {error.message}</Alert>}
        {loading && <Spinner variant="secondary" animation="border" />}
        {!loading && fileUrl &&
          <div className={styles.document}>
            <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} width={800} style={{ backgroundColor: "tomato" }} />
              ))}
            </Document>
          </div>
        }
      </div>
    </div>
  )
};

export default DocumentRenderer;