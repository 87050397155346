import React from "react";
import SectionBanner from "./SectionBanner";
import "./SectionWrapper.scss";

const SectionWrapper = ({children, id, bannerHeight }) => {
  return (
    <section id={id}>
      <SectionBanner height={bannerHeight}>
        <div className="section-wrapper ps-3 pe-3">
          {children}
        </div>
      </SectionBanner>
    </section>
  );
};

export default SectionWrapper;