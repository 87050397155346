import { create } from "zustand";

export const useFileStore = create((set) => ({
    files: [],
    uploadedFileObjects: [],
    uploading: false,
    allSuccessful: false,
    addFiles: (files) => set(state => {
        const newFiles = [...state.files, ...files];
        const newObjects = [
            ...state.uploadedFileObjects,
            ...(Array.from(files).map(() => ({ uploaded: false, uploadReady: false, uploading: false, success: false, error: false })))];
        return ({ files: newFiles, uploadedFileObjects: newObjects });
    }),
    deleteFile: (index) => set(state => {
        const newFiles = [...state.files];
        newFiles.splice(index, 1);
        const newObjects = [...state.uploadedFileObjects];
        newObjects.splice(index, 1);
        const allSuccessful = newObjects.length > 0 && !(newObjects.some(o => o.error || o.uploading)) && newObjects.every(o => o.uploaded);
        return { files: newFiles, uploadedFileObjects: newObjects, allSuccessful: allSuccessful };
    }),
    markUploadsReady: () => set(state => {
        const newObjects = state.uploadedFileObjects.map(o => {
            if (o.uploading || (o.uploaded && o.success)) { // files that are uploading or were uploaded successfully stay unchanged
                return o;
            }
            // files that were not uploaded or failed, are set to uploading
            return ({ uploadReady: true, uploading: false, uploaded: false, success: false, error: false });
        });
        const uploading = newObjects.length > 0 && newObjects.some(o => o.uploading);
        const allSuccessful = newObjects.length > 0 && !(newObjects.some(o => o.error || o.uploading)) && newObjects.every(o => o.uploaded);
        return ({
            uploadedFileObjects: newObjects,
            uploading: uploading,
            allSuccessful: allSuccessful
        });
    }),
    startUpload: (index) => set(state => {
        const fileObject = state.uploadedFileObjects[index];
        if (fileObject.uploading || (fileObject.uploaded && fileObject.success)) {
            return {};
        }

        if (fileObject.uploadReady) {
            const newObjects = [...state.uploadedFileObjects];
            newObjects[index] = { ...fileObject, uploading: true };
            const uploading = newObjects.length > 0 && newObjects.some(o => o.uploading);
            const allSuccessful = newObjects.length > 0 && !(newObjects.some(o => o.error || o.uploading)) && newObjects.every(o => o.uploaded);
            return ({
                uploadedFileObjects: newObjects,
                uploading: uploading,
                allSuccessful: allSuccessful
            });
        }

        return {};
    }),
    setUploadSuccess: (index, fileObject) => set(state => {
        const newObjects = [...state.uploadedFileObjects];
        newObjects[index] = { ...fileObject, uploading: false, uploaded: true, success: true, error: false };
        const uploading = newObjects.length > 0 && newObjects.some(o => o.uploading);
        const allSuccessful = newObjects.length > 0 && !(newObjects.some(o => o.error || o.uploading)) && newObjects.every(o => o.uploaded);
        return ({ uploadedFileObjects: newObjects, uploading: uploading, allSuccessful: allSuccessful });
    }),
    setUploadFailure: (index, errorMessage) => set(state => {
        const newObjects = [...state.uploadedFileObjects];
        newObjects[index] = { uploading: false, uploaded: true, success: false, error: true, errorMessage };
        const uploading = newObjects.length > 0 && newObjects.some(o => o.uploading);
        return ({ uploadedFileObjects: newObjects, uploading: uploading, allSuccessful: false });
    }),
    clear: () => set({
        files: [],
        uploadedFileObjects: [],
        uploading: false,
        allSuccessful: false
    })
}));