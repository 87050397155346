import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink, Outlet, useParams } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import { SAMPLE_PROJECTS } from "../../sample/sampleProjects";
import styles from "./Project.module.scss";

const INITIAL_PATH = { title: "Proyectos", url: "/projects" };

const Project = () => {
  const [breadcrumbPaths, setBreadcrumbPaths] = useState([]);
  const [project, setProject] = useState();

  const { projectId } = useParams();

  useEffect(() => {
    setProject(projectId ? SAMPLE_PROJECTS.find(p => p.id === projectId) : undefined);
  }, [projectId]);

  useEffect(() => {
    const paths = [ INITIAL_PATH ];

    if (project) {
      paths.push({
        title: project.title,
        url: `/projects/${project.id}`
      });
    }

    setBreadcrumbPaths(paths);
  }, [project]);

  return (
    <section id={styles.project}>
      <Breadcrumb paths={breadcrumbPaths} />
      {project &&
        <>
          <h3 className={styles.title}>{project.title}</h3>

          <Row>
            <Col xs={12} xl={8}>
              <div className="d-flex mb-4">
                <NavLink to={`/projects/${project.id}/docs`} className={({ isActive }) => `${styles.tab} ${isActive ? styles.active : ''}`} style={{ flex: 1 }}>
                  Archivos PDF
                </NavLink>
                <NavLink to={`/projects/${project.id}/videos`} className={({ isActive }) => `${styles.tab} ${isActive ? styles.active : ''}`} style={{ flex: 1 }}>
                  Videos
                </NavLink>
                {/* <NavLink to={`/projects/${project.id}/news`} className={({ isActive }) => `${styles.tab} ${isActive ? styles.active : ''}`} style={{ flex: 1 }}>
                  Noticias
                </NavLink> */}
              </div>
            </Col>
          </Row>

          <Outlet />
        </>
      }
    </section>
  );
};

export default Project;