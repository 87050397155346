import icGranos from "../img/icons/trigo.png";
import icPecan from "../img/icons/comida.png";
import icOlivos from "../img/icons/olivos.png";
import icGanaderia from "../img/icons/vaca.png";
import call from "./call";

const INVESTMENTS_URL = `RghtBbstxc`
const INVESTMENT_URL = `E-qa2l9zu9?investmentProduct=`
const INVESTMENT_STATE_URL = `HTBY-dkghL?investmentProject=`

const Investments = {
    list: () => call(`${INVESTMENTS_URL}`).then(inv => inv.map(i => ({
        id: i.investmentProduct,
        title: i.investmentProduct,
        campaign: i.campaign,
        productTitle: i.productTitle,
        projectTitle: i.projectTitle,
        state: i.investmentProductStateMessage,
        value: new Intl.NumberFormat('es-AR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        }).format(i.total),
        ...(investmentsConfig[i.investmentProject])
    }))),
    get: (inv) => call(`${INVESTMENT_URL}${inv}`).then(invs => invs && invs.length > 0 && invs[0]),
    getState: (inv) => call(`${INVESTMENT_STATE_URL}${inv}`).then(summarizeInvestmentState)
};

const investmentsConfig = {
    'GRANOS': {
        type: 'granos',
        color: '#FFD204',
        icon: icGranos
    },
    'PECAN': {
        color: "#522C1C",
        icon: icPecan,
        type: "pecan"
    },
    'OLIVOS': {
        color: "#A29200",
        icon: icOlivos,
        type: "olivos"
    },
    'GANADERIA': {
        color: "#7E2D18",
        icon: icGanaderia,
        type: "ganaderia"
    }
};

const DEFAULT_LIQ = {
    saldoInicio: 0,
    aportes: 0,
    reasignaciones: 0,
    capital: 0,
    renta: 0,
    resultado: 0,
    inversionSinImpuestos: 0,
    bienesPersonales: 0,
    retencionGanancias: 0,
    retencionGananciasAuto: 0,
    gananciaMinimaPresunta: 0,
    impDebCred: 0,
    efectoImpCampana: 0,
    efectoImpAcumulado: 0,
    retiros: 0,
    otros: 0,
    saldoCuenta: 0,
    capitalFideicomitido: 0,
    participacion: 0
}

const summarizeInvestmentState = (invs) => {
    const result = [];
    invs.forEach(inv => {
        let liqObject = result.find(l => l.liquidacion === inv.campaign);
        if (!liqObject) {
            liqObject = {
                liquidacion: inv.campaign,
                ...DEFAULT_LIQ
            };
            result.push(liqObject);
        }
        
        const summarizer = summarizers[inv.operationType];
        if (summarizer) {
            summarizer(liqObject, inv);
        }
    });

    result.sort((a, b) => b.liquidacion.localeCompare(a.liquidacion));

    if (result.length > 0) {
        result[0].current = true;
    }

    result.forEach(calculateAmounts);

    let impuestosAcum = 0;
    for (let index = result.length - 1; index >= 0; index--) {
        const liq = result[index];
        impuestosAcum += liq.efectoImpCampana;
        liq.efectoImpAcumulado = impuestosAcum;
    }

    return result;
};

const summarizers = {
    'SALDO AL INICIO': (liq, inv) => { liq.saldoInicio = inv.total },
    'APORTE': (liq, inv) => { liq.aportes = inv.total },
    'REASIGNACION': (liq, inv) => { liq.reasignaciones = inv.total },
    'SALDO AL CIERRE': (liq, inv) => { liq.saldoCuenta = inv.total },
    'RENTA': (liq, inv) => { liq.renta = inv.total },
    'IMPUESTOS': (liq, inv) => {
        if (!liq.impuestos) {
            liq.impuestos = {};
        }
        liq.impuestos[inv.operationSubType] = inv.amountUSD;
        liq.efectoImpCampana = liq.efectoImpCampana + inv.amountUSD;
    },
    'RETIRO': (liq, inv) => { liq.retiros = inv.total },
    'CAPITAL FIDEICOMITIDO (USD)': (liq, inv) => { liq.capitalFideicomitido = inv.total },
    'PARTICIPACION (%)': (liq, inv) => { liq.participacion = inv.total / 100 }
}

const calculateAmounts = (liq) => {
    liq.capital = (liq.saldoInicio || 0) + liq.aportes + liq.reasignaciones;
    liq.resultado = liq.renta;
    liq.inversionSinImpuestos = liq.capital + liq.renta;
};



export default Investments;