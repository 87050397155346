import React, { useCallback } from "react";
import styles from "./Attachments.module.scss";
import { FileUploader } from "react-drag-drop-files";
import { Button, Spinner } from "react-bootstrap";
import moment from "moment";
import { useFileStore } from "./fileStore";
import { shallow } from "zustand/shallow";
import { useDocumentStore } from "./documentStore";
import Api from "../../api";

const Attachments = ({ isSubmitting }) => {
  const [fileList, addFiles, deleteFile] = useFileStore(state => [
    state.files, state.addFiles, state.deleteFile, state.uploadedFileObjects
  ], shallow);
  const documents = useDocumentStore(state => state.documents);

  return (
    <div className={styles.root}>
      {!isSubmitting &&
        <FileUploader handleChange={addFiles} multiple classes={styles.files}>
          <div className={styles.drop}>
            <i className="fas fa-file-arrow-up" />
            Arrastre aquí los documentos que desea adjuntar
          </div>
        </FileUploader>
      }
      {fileList && fileList.length > 0 &&
        <>
          <h6>Documentos a adjuntar</h6>
          <div className={styles.fileList}>
            {fileList && fileList.map((file, i) => (
                <UploadedFile key={i} file={file} index={i} onDelete={deleteFile} isSubmitting={isSubmitting} />
            ))}
          </div>
        </>
      }
      {documents && documents.length > 0 &&
        <>
          {fileList && fileList.length > 0 && <h6>Documentos adjuntos</h6>}
          <div className={styles.docList}>
            {documents.map((d, i) => (
              <ProfileDocument key={i} document={d} isSubmitting={isSubmitting} />
            ))}
          </div>
        </>
      }
    </div>
  );
};

const ProfileDocument = ({ document, isSubmitting }) => {
  const [deleteDoc, restoreDoc] = useDocumentStore(state => [
    state.deleteDoc, state.restoreDoc
  ], shallow);

  const onDelete = useCallback(() => {
    deleteDoc(document.fileId);
  }, [deleteDoc, document]);
  const onRestore = useCallback(() => {
    restoreDoc(document.fileId);
  }, [restoreDoc, document]);
  const onDownload = useCallback(e => {
    Api.Docs.getFileUrl(document.fileId).then(file => {
      if (file) {
        window.open(file.url, '_blank');
      }
    });
  }, [document]);
  const fecha = moment(document.date).format(`DD/MM/YYYY`);
  const deleteStyle = document.markedToDelete ? { visibility: 'hidden' } : {};
  return (
    <div className={styles.doc}>
      <div className={styles.docContainer}>
        <div className={styles.docData}>
          <span className={styles.docFileName}>{document.fileName}</span>
          <div className="d-flex">
            <div className={styles.docDate}>Fecha: {fecha}</div>
            <div className="flex-1">Tipo: {document.documentName}</div>
          </div>
        </div>
        <Button variant="link" onClick={onDownload} disabled={isSubmitting}>
          <i className="fas fa-file-arrow-down" />
        </Button>
        <Button variant="link" onClick={onDelete} disabled={isSubmitting} style={deleteStyle}>
          <i className="fas fa-circle-xmark" />
        </Button>
      </div>
      {document.markedToDelete &&
        <div className={styles.docDeleted}>
          <span className={styles.msg}>Este documento será eliminado</span>
          <Button variant="link" onClick={onRestore}>Deshacer</Button>
        </div>
      }
    </div>
  );
};

const UploadedFile = ({ file, index, onDelete: onDeleteCallback, isSubmitting }) => {
  const onDelete = useCallback(e => {
    onDeleteCallback && onDeleteCallback(index);
  }, [index, onDeleteCallback]);
  return (
    <div className={styles.file}>
      <span className={styles.fileName}>{file.name}</span>

      {file.uploading && <Spinner animation="border" size="sm" variant="primary" />}
      <Button variant="link" onClick={onDelete} disabled={isSubmitting}>
        <i className="fas fa-circle-xmark" />
      </Button>
    </div>
  );
};

export default Attachments;