import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Api from "../../api";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import DocCard from "../../components/cards/DocCard";
// import { SAMPLE_INVESTMENTS } from "../../sample/sampleInvestments";
import styles from "./InvestmentDocument.module.scss";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { pdfjs } from 'react-pdf';
import DocumentRenderer from "../../components/documents/DocumentRenderer";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const INITIAL_PATH = { title: "Mis Inversiones", url: "/investments" };

const InvestmentDocument = () => {
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState([]);
  const [doc, setDoc] = useState();
  const [breadcrumbPaths, setBreadcrumbPaths] = useState([]);
  // const [investment, setInvestment] = useState();

  const { investmentId, docId } = useParams();

  useEffect(() => {
    setLoading(true);
    Api.Docs.getInvestmentDocs(investmentId).then(setDocs).then(() => setLoading(false));
  }, [investmentId]);

  // useEffect(() => {
  //   setInvestment(investmentId ? SAMPLE_INVESTMENTS.find(i => i.id === investmentId) : undefined);
  // }, [investmentId]);

  useEffect(() => {
    setDoc(docId ? docs.find(d => d.id === docId) : undefined);
  }, [docs, docId]);

  useEffect(() => {
    const paths = [ INITIAL_PATH ];

    if (investmentId) {
      paths.push({
        title: investmentId,
        url: `/investments/${investmentId}`
      });

      paths.push({
        title: 'Documentación de Proyecto',
        url: `/investments/${investmentId}/docs`
      })
    }

    setBreadcrumbPaths(paths);
  }, [investmentId]);



  return (
    <section id={styles.investmentdocs}>
      <Breadcrumb paths={breadcrumbPaths} />
      <h3 className={styles.title}>Documentación de Proyecto</h3>

      <Row>
        <Col xs={12} xl={8}>
        {doc && <DocumentRenderer doc={doc} />}
        </Col>
        <Col className="d-flex flex-column mt-3 mt-xl-0" xs={12} xl={4}>
          <h4 style={{ fontSize: "14pt", borderBottom: "1px solid #7070704d" }} className="mb-4 pb-3">Archivos PDF</h4>
          {investmentId && loading && <Spinner variant="secondary" animation="border" style={{ marginTop: '0.5rem' }} />}
          {docs.map((d, i) => <DocCard key={i} doc={d} linkTo={`/investments/${investmentId}/${d.id}`} selected={docId && d.id === docId} />)}
        </Col>
      </Row>
    </section>
  );
};

export default InvestmentDocument;