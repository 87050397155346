import { Button, Modal } from "react-bootstrap";
// import profilePicture from "../../img/contact-profile.png";
import { useCallback, useEffect, useState } from "react";
import Api from "../../api";

const ManagerModal = ({ onClose }) => {
  const [manager, setManager] = useState({});
  useEffect(() => {
    Api.Manager.get().then(setManager);
  }, []);

  const onMessage = useCallback(() => {
    if (manager.managerPhone) {
      window.open(`https://wa.me/+${manager.managerPhone}`);
    }
  }, [manager.managerPhone]);

  return (
    <Modal show onHide={onClose} centered size="lg">
      <Modal.Body>
        <div className="d-flex flex-column">
          <Button variant="link" className="align-self-end" onClick={onClose}><i className="fas fa-times" /></Button>
          <div className="d-flex w-100 pb-4">
            <div className="d-flex flex-column align-items-center px-5 py-4 my-2" style={{ width: '50%', borderRight: '1px solid #707070'}}>
              <div className="align-items-center bg-secondary d-flex justify-content-center mb-2 rounded-circle text-light"
                style={{ width: 100, height: 100, fontSize: '48px' }}><i className="fas fa-user" /></div>
              {/* <img src={profilePicture} className="rounded-circle mb-2" width={100} height={100} alt="Contact"/> */}
              <div style={{ fontSize: '14pt' }}>{manager.managerName}</div>
              {manager.managerPhone && <Button className="my-4 px-5" onClick={onMessage}>WhatsApp</Button>}
            </div>
            <div className="d-flex flex-column justify-content-center px-4" style={{ width: '50%' }}>
              <p><strong>Ejecutivo de Cuenta</strong></p>
              <p>Tel: {manager.managerPhone}</p>
              <p>Mail: {manager.managerEmail}</p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ManagerModal;