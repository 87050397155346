import React from "react";
import { Link } from "react-router-dom";
import styles from "./Breadcrumb.module.scss";

const Breadcrumb = ({ paths }) => {
  return (
    <div className={styles.root}>
      {paths && paths.map((p, index) => {
        return (
          <>
            {index > 0 && <span className={styles.separator}>|</span>}
            <span><Link to={p.url} className={index === paths.length - 1 ? styles.active : styles.parent}>{p.title}</Link></span>
          </>
        );
      })}
    </div>
  );
};

export default Breadcrumb;