import React, { useCallback, useState } from "react";
import styles from "./PasswordChange.module.scss";
import SectionWrapper from "../../components/sections/SectionWrapper";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { Form as FormikForm, Formik, Field } from "formik";
import { useNavigate } from "react-router-dom";
import Api from "../../api";

const initialValues = {
  newPassword: '',
  confirmPassword: ''
};

const PasswordChange = () => {
  const navigate = useNavigate();

  const validate = useCallback((values) => {
    const errors = {};

    const { newPassword, confirmPassword } = values;

    if (newPassword.length < 8) {
      errors.newPassword = 'La contraseña debe tener al menos 8 caracteres.';
    }
    if (newPassword.toLowerCase() === newPassword) {
      errors.newPassword = 'Debe contener al menos una letra mayúscula.';
    }
    if (newPassword.toUpperCase() === newPassword) {
      errors.newPassword = 'Debe contener al menos una letra minúscula.';
    }
    if (!(/\d/.test(newPassword))) {
      errors.newPassword = 'Debe contener al menos un número.';
    }
    if (newPassword !== confirmPassword) {
      errors.confirmPassword = 'Las contraseñas no coinciden.';
    }

    return errors;
  }, []);

  const [lastSubmitted, setLastSubmitted] = useState();
  const [submitError, setSubmitError] = useState();
  const onSubmit = useCallback((values, { setSubmitting, resetForm }) => {
    setSubmitError(undefined);
    Api.Session.changePassword(values.newPassword).then(res => {
      setLastSubmitted(Date.now());
      resetForm();
      setSubmitting(false);
    }).catch(err => {
      console.error('error changing password', err);
      setSubmitError(err.message);
    });
  }, []);
  const onCancel = useCallback(() => navigate('/'), [navigate]);

  return (
    <SectionWrapper id="pwdchange" bannerHeight={320}>
      <div className={styles.pwdChange}>
        <h4>Cambio de contraseña</h4>
        <p>La contraseña debe cumplir con los siguientes requisitos:</p>
        <ul>
          <li>Al menos 8 caracteres</li>
          <li>Contener al menos una letra minúscula</li>
          <li>Contener al menos una letra mayúscula</li>
          <li>Contener al menos un número</li>
        </ul>
        <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit} enableReinitialize>
          {({ isSubmitting, errors }) => (
            <FormikForm>
              <Form.Group controlId="newPassword">
                <Form.Label>Nueva contraseña</Form.Label>
                <Form.Control type="password" name="newPassword" placeholder="Nueva contraseña" required
                  disabled={isSubmitting} as={Field} isInvalid={!!errors.newPassword} />
                <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mt-3" controlId="confirmPassword">
                <Form.Label>Confirmar contraseña</Form.Label>
                <Form.Control type="password" name="confirmPassword" placeholder="Confirmar contraseña" required
                  disabled={isSubmitting} as={Field} isInvalid={!!errors.confirmPassword} />
                <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
              </Form.Group>

              {lastSubmitted && !isSubmitting && !submitError &&
                <Alert variant="info" className="mt-4">El cambio de contraseña se realizó exitosamente.</Alert>
              }
              {submitError && <Alert variant="danger" className="mt-4">Ocurrió un error al cambiar la contraseña: {submitError}</Alert>}

              <div className={styles.formFooter}>
                <Button variant="link" onClick={onCancel} disabled={isSubmitting}>Volver al inicio</Button>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? <Spinner variant="light" animation="border" role="status" size="sm" /> : 'Modificar'}
                </Button>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </SectionWrapper>
  );
};

export default PasswordChange;