import { Field } from "formik";
import React from "react";
import { Form } from "react-bootstrap";

const Select = ({ name, children, required, disabled }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <Form.Select {...field} required={required} disabled={disabled}>
          {children}
        </Form.Select>
      )}
    </Field>
  );
};

export default Select;