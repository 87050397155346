import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./ProjectCard.module.scss";

const ProjectCard = ({ id, title, coverImage, date }) => {
  return (
    <div className={styles.root + " custom-card"}>
      <div className={styles.title}>{title}</div>
      <img src={coverImage} alt="Project cover" />
      <div className={styles.footer}>
        <span className={styles.date}>{' '}</span>
        <Link to={`/projects/${id}/docs`}><Button color="primary" size="sm">Ver</Button></Link>
      </div>
    </div>
  );
};

export default ProjectCard;